import React from 'react';
import styled from 'styled-components';
import { Container } from '@tymate/elise/ui';
import { createWeighing, getWeighingsWorkOrder } from 'api/weighing';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Breadcrumb } from '@tymate/elise/components';
import { Stack, Spinner } from '@tymate/margaret';
import WeighingForm from 'components/Forms/WeighingForm';
import { omit } from 'lodash';
import {
  calculateTotalMeasurement,
  formatWorkOrderTasks,
  getBillingMeasurementByBillingUnit,
  getWorkOrderStatus,
} from 'utils';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppState } from 'hooks';
import { ERRORS } from '@tymate/elise/utils';

const BreadcrumbContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing()};
  margin-left: ${({ theme }) => theme.spacing(-2)};
`;

const WeighingContent = ({ workOrder, handleSubmit }) => {
  const tasks = formatWorkOrderTasks(workOrder?.id, workOrder?.workOrderTasks);

  return (
    <Stack gutterSize={1.5} direction="column" paddingBottom={6}>
      <WeighingForm
        tasks={tasks}
        workOrderId={workOrder?.id}
        onSubmit={handleSubmit}
        summaryData={workOrder}
      />
    </Stack>
  );
};

const NewWeighing = ({ match }) => {
  const { workOrderId } = match.params;
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { notify } = useAppState();

  const {
    data: workOrderData,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(
    ['workOrderWeighing', workOrderId],
    () =>
      getWeighingsWorkOrder({
        workOrderId,
      }),
    {
      onSuccess: response => {
        if (response?.data?.weighingId) {
          history.push(`/pesees/${response?.data?.weighingId}/modifier`);
        }
      },
    },
  );
  const workOrder = workOrderData?.data;

  const { mutateAsync } = useMutation(values => createWeighing(values), {
    onSuccess: () => {
      queryClient.invalidateQueries(['weighingList']);
      notify('Cette pesée a bien été créée.');

      if (location?.search) {
        history.push(`/pesees${location.search}`);
      } else {
        history.push('/pesees');
      }
    },
    onError: () => {
      notify(ERRORS.COMMON, {
        type: 'error',
      });
    },
  });

  const handleSubmit = async data => {
    const payload = {
      ...data,
      status: getWorkOrderStatus(data?.weighingTasks)?.value,
      weighingTasks: data?.weighingTasks?.map(weighingTask => {
        const totalMeasurement = calculateTotalMeasurement(weighingTask);
        const weighingTaskBillingMeasurement =
          getBillingMeasurementByBillingUnit(weighingTask, totalMeasurement);

        return {
          ...omit(weighingTask, [
            'ref',
            'title',
            'identifiedContainersRequired',
            'productResourceUnit',
            'billingUnit',
          ]),
          weighingLines: weighingTask?.weighingLines.map(weighingLine => {
            return {
              ...weighingLine,
              containerResourceId:
                weighingLine?.containerResourceId?.value ||
                weighingLine?.containerResourceId,
            };
          }),
          descendantWeighingTasks: weighingTask.descendantWeighingTasks.map(
            descendantWeighingTask => {
              let descendantWeighingTaskBillingMeasurement = 0;

              if (descendantWeighingTask.billingUnit?.value === 'unsto') {
                descendantWeighingTaskBillingMeasurement = totalMeasurement;
              } else if (descendantWeighingTask.billingUnit?.value === 'pres') {
                descendantWeighingTaskBillingMeasurement = 1;
              } else if (descendantWeighingTask.billingMethod === 'fixed') {
                descendantWeighingTaskBillingMeasurement = 1;
                descendantWeighingTask.totalMeasurement = totalMeasurement;
              } else {
                if (
                  !descendantWeighingTask?.billingMeasurement ||
                  [
                    'unsto',
                    'pres',
                    descendantWeighingTask.billingUnitParent?.value,
                  ].includes(descendantWeighingTask.billingUnit?.value)
                ) {
                  descendantWeighingTaskBillingMeasurement =
                    weighingTaskBillingMeasurement;
                } else {
                  descendantWeighingTaskBillingMeasurement =
                    descendantWeighingTask.billingMeasurement;
                }
              }

              return {
                ...omit(descendantWeighingTask, ['title', 'billingUnitParent']),
                billingMeasurement: descendantWeighingTaskBillingMeasurement,
              };
            },
          ),
          downgrading: {
            ...weighingTask?.downgrading,
            weighingLines: weighingTask?.downgrading?.weighingLines?.map(
              weighingLine => {
                return {
                  ...weighingLine,
                  containerResourceId:
                    weighingLine?.containerResourceId?.value ||
                    weighingLine?.containerResourceId,
                };
              },
            ),
          },
          totalMeasurement,
          billingMeasurement: weighingTaskBillingMeasurement,
          reportWeighings: weighingTask.reportWeighings.map(reportWeighing => ({
            ...reportWeighing,
            unit: reportWeighing.unit.value,
          })),
        };
      }),
      extraWeighingTasks: data?.extraWeighingTasks.map(extraWeighingTask => ({
        ...extraWeighingTask,
        resourceId: extraWeighingTask?.resourceId?.value,
        serviceId: extraWeighingTask?.serviceId?.value,
        containerResourceId:
          extraWeighingTask?.containerResourceId?.value ||
          extraWeighingTask?.containerResourceId,
      })),
    };

    await mutateAsync(payload);
  };

  return (
    <Container variant="main">
      <BreadcrumbContainer>
        <Breadcrumb />
      </BreadcrumbContainer>
      {isLoading && <Spinner />}
      {isError && <div>Une erreur est survenue</div>}
      {isSuccess && (
        <WeighingContent workOrder={workOrder} handleSubmit={handleSubmit} />
      )}
    </Container>
  );
};

export default NewWeighing;
