import { Stack } from '@tymate/margaret';
import { Button } from '@tymate/elise/components';
import styled from 'styled-components';
import { ActionButton, Buttons } from '@tymate/elise/ui';
import {
  useForm,
  FormProvider,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import useYupResolver from '@tymate/elise/hooks/useYupResolver';
import * as Yup from 'yup';
import TextField from '@tymate/elise/components/Fields/TextField';
import TextareaField from '@tymate/elise/components/Fields/TextareaField';
import SelectSearchQueryField from 'components/Fields/SelectSearchQueryField';
import { getResources } from 'api/references';
import { MdClose } from 'react-icons/md';
import { useDeepCompareEffect, useEffectOnce } from 'react-use';
import React, { useState } from 'react';
import { validationFloat } from '@tymate/elise/utils/validation';
import { calculateTotalWeight } from 'utils';

const NetTotalWithoutTare = ({ control, productResourceUnit }) => {
  const downgrading = useWatch({
    control,
  });

  const totalWeight = calculateTotalWeight(
    downgrading,
    productResourceUnit,
    downgrading?.totalTare,
  );

  return (
    <>
      <div />
      <Stack alignX="right" alignY="center" marginRight={-0.125}>
        <strong>
          {productResourceUnit === 'un'
            ? 'Total :'
            : 'Total net tare déduite :'}
        </strong>
      </Stack>
      <Stack
        size="full"
        alignX="right"
        alignY="center"
        paddingHorizontal={0.5}
        style={{
          fontSize: '18px',
        }}
      >
        <strong>{totalWeight}</strong>
      </Stack>
      <Stack
        alignY="center"
        alignX="right"
        style={{
          fontSize: '18px',
        }}
      >
        <strong>{productResourceUnit}</strong>
      </Stack>
      <div />
    </>
  );
};

const Separator = styled(Stack)`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.separator};
`;

const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 5fr 5fr 3fr 1fr 24px;
  gap: 0px 12px;
  min-width: 100%;
`;

const VALIDATION_SCHEMA = Yup.object().shape({
  totalTare: validationFloat(),
  weighingLines: Yup.array().of(
    Yup.object().shape({
      containerCount: validationFloat(),
      measurement: validationFloat(),
    }),
  ),
});

const DowngradingForm = ({ onSubmit, toggleShow, task }) => {
  const resolver = useYupResolver(VALIDATION_SCHEMA);
  const methods = useForm({
    resolver,
    defaultValues: {
      ...task?.downgrading,
    },
  });
  const downgradingWeighingLines = useWatch({
    control: methods.control,
    name: 'weighingLines',
  });
  const [isSubmittable, setIsSubmittable] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const { append, remove, fields } = useFieldArray({
    control: methods.control,
    name: 'weighingLines',
  });

  const handleAddALine = () => {
    append({
      orderLineResourceId: null,
      containerResourceId: null,
      container: '',
      containerCount:
        !task?.identifiedContainersRequired &&
        task?.productResourceUnit === 'kg'
          ? 1
          : null,
      identifier: '',
      measurement: null,
    });
  };

  useEffectOnce(() => {
    if (
      !task?.downgrading?.weighingLines?.some(
        weighingLine => !weighingLine?._destroy,
      )
    ) {
      handleAddALine();
    }
  });

  const watchContainers = methods.watch(
    fields?.map((_, index) => `weighingLines[${index}].containerResourceId`),
  );

  const watchContainersCount = methods.watch(
    fields?.map((_, index) => `weighingLines[${index}].containerCount`),
  );

  const watchWeighingLineMeasurements = useWatch({
    control: methods?.control,
    name: fields?.map((_, index) => `weighingLines[${index}].measurement`),
  });

  const firstDisplayedMeasurement = downgradingWeighingLines?.find(
    downgradingWeighingLine => downgradingWeighingLine._destroy !== true,
  )?.measurement;

  useDeepCompareEffect(() => {
    setIsSubmittable(
      firstDisplayedMeasurement?.length > 0 || firstDisplayedMeasurement > 0,
    );

    if (task?.downgrading?.totalTare && isFirstLoading) {
      setIsFirstLoading(false);
      return;
    }

    if (downgradingWeighingLines) {
      let computedTare = 0;
      downgradingWeighingLines.forEach(weighingLine => {
        task?.identifiedContainersRequired
          ? (computedTare += weighingLine?.containerResourceId?.tare || 0)
          : (computedTare +=
              weighingLine?.containerResourceId?.tare *
                weighingLine?.containerCount || 0);
      });
      if (isNaN(computedTare)) {
        computedTare = 0;
      }
      methods.setValue('totalTare', computedTare);
    }
  }, [
    {
      taskTotalTare: task?.downgrading?.totalTare,
      watchContainers,
      downgradingWeighingLines,
      watchWeighingLineMeasurements,
      watchContainersCount,
    },
  ]);

  const handleRemove = index => {
    if (downgradingWeighingLines?.[index]?.id) {
      methods.setValue(`weighingLines[${index}]._destroy`, true);
    }
    remove(index);
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Stack direction="column" gutterSize={2} size="full">
          <Separator />
          <div>Contenant(s)</div>
          {fields?.map((item, index) => {
            if (!item._destroy) {
              return (
                <ItemGrid key={item.id}>
                  <Stack size="full">
                    <SelectSearchQueryField
                      name={`weighingLines[${index}].containerResourceId`}
                      queryKey={['resources']}
                      queryFn={getResources}
                      params={{ container: true }}
                      placeholder="Contenant..."
                      labelKey="name"
                      labelValue="id"
                      initialValueLabel={item.containerResourceId?.label}
                      storeEntireOption
                      optionalKeys={['tare']}
                    />
                  </Stack>
                  <Stack
                    gutterSize={0.5}
                    size="full"
                    alignY="center"
                    alignX="flex-end"
                  >
                    {task?.identifiedContainersRequired ? (
                      <TextField
                        name={`weighingLines[${index}].identifier`}
                        placeholder="Identification"
                      />
                    ) : (
                      <TextField
                        name={`weighingLines[${index}].containerCount`}
                        placeholder="Quantité"
                      />
                    )}
                  </Stack>
                  <Stack
                    size="full"
                    alignY="center"
                    style={{ margin: '0px 8px' }}
                  >
                    <TextField
                      name={`weighingLines[${index}].measurement`}
                      textAlign="right"
                    />
                  </Stack>
                  <Stack alignX="right" size="full" alignY="center">
                    {task?.productResourceUnit}
                  </Stack>
                  <Stack size="full" alignY="center">
                    <ActionButton
                      type="button"
                      onClick={() => {
                        handleRemove(index);
                      }}
                    >
                      <MdClose />
                    </ActionButton>
                  </Stack>
                </ItemGrid>
              );
            } else {
              return null;
            }
          })}

          <ItemGrid>
            <Stack size="full" alignY="center" alignX="space-between">
              <Button
                variant="outline"
                size="small"
                onClick={handleAddALine}
                type="button"
              >
                Ajouter une ligne
              </Button>
            </Stack>
            {task?.productResourceUnit !== 'un' && (
              <>
                <Stack
                  alignY="center"
                  alignX="right"
                  style={{
                    fontSize: '14px',
                  }}
                >
                  Tare totale :
                </Stack>
                <Stack
                  size="full"
                  alignY="center"
                  style={{ margin: '0px 8px' }}
                >
                  <TextField name="totalTare" textAlign="right" />
                </Stack>
                <Stack alignX="right" size="full" alignY="center">
                  {task?.productResourceUnit}
                </Stack>
              </>
            )}
          </ItemGrid>
          <ItemGrid>
            <NetTotalWithoutTare
              tare={task?.totalTare}
              productResourceUnit={task?.productResourceUnit}
              control={methods.control}
            />
          </ItemGrid>
          <TextareaField
            label="Description"
            name="description"
            minRows={3}
            control={methods.control}
          />
          <Separator />
          <Buttons
            style={{ marginTop: '1.5rem' }}
            alignment="right"
            size="full"
          >
            <Button
              type="button"
              isLoading={methods.formState.isSubmitting}
              variant="outline"
              onClick={toggleShow}
            >
              Annuler
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={!isSubmittable}
            >
              Valider
            </Button>
          </Buttons>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default DowngradingForm;
