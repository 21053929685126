import React from 'react';
import styled from 'styled-components';
import { Container } from '@tymate/elise/ui';
import { getWeighing, updateWeighing } from 'api/weighing';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Breadcrumb } from '@tymate/elise/components';
import { Stack, Spinner } from '@tymate/margaret';
import WeighingForm from 'components/Forms/WeighingForm';
import { omit } from 'lodash';
import {
  calculateTotalMeasurement,
  formatExtraWeighingTasks,
  formatWeighingTasks,
  getBillingMeasurementByBillingUnit,
  getWorkOrderStatus,
} from 'utils';
import { useAppState } from 'hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { ERRORS } from '@tymate/elise/utils';

const BreadcrumbContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing()};
  margin-left: ${({ theme }) => theme.spacing(-2)};
`;

const WeighingContent = ({ weighing, handleSubmit, isControl }) => {
  const tasks = formatWeighingTasks(
    weighing?.id,
    weighing?.workOrderId,
    weighing?.weighingTasks,
  );
  const extraTasks = formatExtraWeighingTasks(weighing?.extraWeighingTasks);

  return (
    <Stack gutterSize={1.5} direction="column" paddingBottom={6}>
      <WeighingForm
        tasks={tasks}
        extraTasks={extraTasks}
        workOrderId={weighing?.id}
        onSubmit={handleSubmit}
        summaryData={weighing}
        isControl={isControl}
      />
    </Stack>
  );
};

const EditWeighing = ({ match }) => {
  const { weighingId } = match.params;
  const isControl = match.path === '/saisies/controle-des-odm/:weighingId';
  const location = useLocation();
  const { notify } = useAppState();
  const queryClient = useQueryClient();
  const history = useHistory();

  const {
    data: weighingData,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(['weighing', weighingId], () =>
    getWeighing({
      weighingId,
    }),
  );

  const weighing = weighingData?.data;

  const { mutateAsync } = useMutation(values => updateWeighing(values), {
    onSuccess: () => {
      notify('Cette pesée a bien été modifiée.');
      queryClient.invalidateQueries(['weighing', weighingId]);

      if (isControl) {
        queryClient.invalidateQueries(['entriesList']);
      } else {
        queryClient.invalidateQueries(['weighingList']);
      }

      const pathname = isControl ? '/saisies/controle-des-odm' : '/pesees';

      if (location?.search) {
        history.push(`${pathname}${location?.search}`);
      } else {
        history.push(pathname);
      }
    },
    onError: () => {
      notify(ERRORS.COMMON, {
        type: 'error',
      });
    },
  });

  const handleSubmit = async data => {
    const payload = {
      ...data,
      status: getWorkOrderStatus(data?.weighingTasks)?.value,
      workOrderId: weighing?.workOrderId,
      weighingTasks: data?.weighingTasks?.map(weighingTask => {
        const totalMeasurement = calculateTotalMeasurement(weighingTask);
        const weighingTaskBillingMeasurement =
          getBillingMeasurementByBillingUnit(weighingTask, totalMeasurement);

        return {
          ...omit(weighingTask, [
            'ref',
            'title',
            'identifiedContainersRequired',
            'productResourceUnit',
            'billingUnit',
          ]),
          weighingLines: weighingTask?.weighingLines.map(weighingLine => {
            return {
              ...omit(weighingLine, ['container', 'unit']),
              containerResourceId: weighingLine?.containerResourceId?.value,
            };
          }),
          descendantWeighingTasks: weighingTask?.descendantWeighingTasks?.map(
            descendantWeighingTask => {
              let descendantWeighingTaskBillingMeasurement = 0;

              if (descendantWeighingTask.billingUnit?.value === 'unsto') {
                descendantWeighingTaskBillingMeasurement = totalMeasurement;
              } else if (descendantWeighingTask.billingMethod === 'fixed') {
                descendantWeighingTaskBillingMeasurement = 1;
                descendantWeighingTask.totalMeasurement = totalMeasurement;
              } else {
                if (
                  !descendantWeighingTask?.billingMeasurement ||
                  [
                    'unsto',
                    'pres',
                    descendantWeighingTask.billingUnitParent?.value,
                  ].includes(descendantWeighingTask.billingUnit?.value)
                ) {
                  descendantWeighingTaskBillingMeasurement =
                    weighingTaskBillingMeasurement;
                } else {
                  descendantWeighingTaskBillingMeasurement =
                    descendantWeighingTask.billingMeasurement;
                }
              }

              return {
                ...omit(descendantWeighingTask, [
                  'title',
                  'billingUnit',
                  'billingUnitParent',
                ]),
                billingMeasurement: descendantWeighingTaskBillingMeasurement,
              };
            },
          ),
          downgrading: {
            ...weighingTask?.downgrading,
            weighingLines: weighingTask?.downgrading?.weighingLines?.map(
              weighingLine => {
                return {
                  ...weighingLine,
                  containerResourceId: weighingLine?.containerResourceId?.value,
                };
              },
            ),
          },
          totalMeasurement,
          billingMeasurement: weighingTaskBillingMeasurement,
          reportWeighings: weighingTask.reportWeighings.map(reportWeighing => ({
            ...reportWeighing,
            unit: reportWeighing.unit.value,
          })),
        };
      }),
      extraWeighingTasks: data?.extraWeighingTasks.map(extraWeighingTask => ({
        ...extraWeighingTask,
        resourceId: extraWeighingTask?.resourceId?.value,
        serviceId: extraWeighingTask?.serviceId?.value,
        containerResourceId: extraWeighingTask?.containerResourceId?.value,
      })),
    };

    await mutateAsync({ weighingId, payload });
  };

  return (
    <Container variant="main">
      <BreadcrumbContainer>
        <Breadcrumb />
      </BreadcrumbContainer>
      {isLoading && <Spinner />}
      {isError && <div>Une erreur est survenue</div>}
      {isSuccess && (
        <WeighingContent
          weighing={weighing}
          handleSubmit={handleSubmit}
          isControl={isControl}
        />
      )}
    </Container>
  );
};

export default EditWeighing;
