import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormField, FormLabel, FormError } from '@tymate/elise/ui/forms';
import styled, { css } from 'styled-components';
import { List, ButtonReset } from '../../ui';

const Wrapper = styled(List)`
  display: flex;
`;

const ItemButton = styled(ButtonReset)`
  padding: ${({ theme }) => theme.spacing(0.75)}
    ${({ theme }) => theme.spacing()};
  color: ${({ theme }) => theme.primary};
  transition: background-color 100ms ease, color 100ms ease;

  &:hover {
    background-color: ${({ theme }) => theme.primaryLighten};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &,
      &:hover {
        background-color: ${({ theme }) => theme.primary};
        color: #fff;
      }
    `}

  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
    color: ${({ theme }) => theme.textDisabled};
    cursor: not-allowed;

    ${({ isActive }) =>
      isActive &&
      css`
        &,
        &:hover {
          background-color: ${({ theme }) => theme.primary};
          color: #fff;
        }
      `}
  }
`;

const Item = styled.li`
  box-shadow: inset 0 0 0 1px #e0e0e0;
  overflow: hidden;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  + li {
    margin-left: -1px;
  }
`;

const SegmentedControl = ({ name, options, formValue, setValue, disabled }) => {
  const [selectedOption, setSelectedOption] = useState(formValue);

  return (
    <Wrapper>
      {options.map(({ label, value }, index) => (
        <Item key={index}>
          <ItemButton
            isActive={value === selectedOption}
            type="button"
            value={value}
            onClick={() => {
              setValue(name, value, { shouldDirty: true });
              setSelectedOption(value);
            }}
            disabled={disabled}
          >
            {label}
          </ItemButton>
        </Item>
      ))}
    </Wrapper>
  );
};

const SegmentedControlField = ({
  name,
  options,
  label,
  disabled,
  noMargin,
}) => {
  const {
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const error = errors[name];
  const formValue = getValues(name);

  return (
    <>
      <FormField noMargin={noMargin}>
        {label && <FormLabel htmlFor={name}>{label}</FormLabel>}

        <SegmentedControl
          options={options}
          formValue={formValue}
          name={name}
          setValue={setValue}
          disabled={disabled}
        />
        {error && error.message && (
          <FormError role="alert">{error.message}</FormError>
        )}
      </FormField>
    </>
  );
};

export default SegmentedControlField;
