import React from 'react';
import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import { Cell, DowngradingWeighingLineRow } from 'ui/entries';
import { Text } from 'ui/typography';

const Wrapper = styled(Stack).attrs({
  size: 'full',
  direction: 'column',
  gutterSize: 0.25,
})`
  border: 1px solid ${({ theme }) => theme.error};
  background-color: ${({ theme }) => theme.primaryLighter};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: ${({ theme }) => theme.spacing(0.75)};
`;

const Downgrading = ({ downgrading, productResourceUnit }) => {
  return (
    <Wrapper>
      <Text fontWeight="bold" fontSize={18}>
        Déclassement de DIB
      </Text>

      <Stack direction="column" size="full">
        {downgrading?.weighingLines?.map(weighingLine => {
          return (
            <DowngradingWeighingLineRow key={weighingLine?.id}>
              <Cell gridArea="material">
                <Text
                  color={downgrading?.extraContainerAlert ? 'error' : undefined}
                >
                  {weighingLine?.containerResource?.name}
                </Text>
              </Cell>
              <Cell gridArea="expected">
                <Text textAlign="end">
                  {weighingLine?.identifier ||
                    `${weighingLine?.containerCount}${
                      weighingLine?.orderLineResource?.estimatedContainerCount
                        ? `/${weighingLine?.orderLineResource?.estimatedContainerCount}`
                        : ''
                    }`}
                </Text>
              </Cell>
              <Cell gridArea="weight">
                <Text textAlign="end">
                  {weighingLine?.measurement} {productResourceUnit}
                </Text>
              </Cell>
            </DowngradingWeighingLineRow>
          );
        })}
        {downgrading?.weighingLines?.length !== 0 && (
          <>
            {productResourceUnit !== 'un' && (
              <DowngradingWeighingLineRow>
                <Cell gridArea="expected">
                  <Text textAlign="end" color="textLight">
                    Total tare :
                  </Text>
                </Cell>
                <Cell gridArea="weight">
                  <Text textAlign="end" color="textLight">
                    {downgrading?.totalTare} {productResourceUnit}
                  </Text>
                </Cell>
              </DowngradingWeighingLineRow>
            )}
            <DowngradingWeighingLineRow>
              <Cell gridArea="expected">
                <Text textAlign="end" fontWeight="bold">
                  {productResourceUnit === 'un'
                    ? 'Total :'
                    : 'Total net tare déduite :'}
                </Text>
              </Cell>
              <Cell gridArea="weight">
                <Text textAlign="end" fontWeight="bold">
                  {downgrading?.totalMeasurement} {productResourceUnit}
                </Text>
              </Cell>
            </DowngradingWeighingLineRow>
          </>
        )}
      </Stack>

      <Text fontWeight="bold" fontSize={16}>
        Description
      </Text>
      <Text>{downgrading.description}</Text>
    </Wrapper>
  );
};

export default Downgrading;
