import React from 'react';
import { Stack } from '@tymate/margaret';
import styled from 'styled-components';
import { ActionButton, theme } from '@tymate/elise/ui';
import { Cell } from 'ui';
import { MdDelete, MdEdit } from 'react-icons/md';

const DowngradingWrapper = styled(Stack).attrs({
  size: 'full',
  direction: 'column',
  gutterSize: '2',
  padding: 1,
})`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.grayLighten};
`;

const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 5fr 5fr 3fr 1fr 24px;
  gap: 0px 12px;
  min-width: 100%;
`;

const DowngradingDisplay = ({
  task,
  columnsFlex,
  toggleShowDeleteDowngradeModal,
  toggleShowDowngradingModal,
}) => {
  return (
    <DowngradingWrapper>
      <Stack alignX="space-between" size="full">
        <strong>Déclassement en DIB</strong>
        <Stack>
          <ActionButton type="button" onClick={toggleShowDeleteDowngradeModal}>
            <MdDelete color={theme.gray} />
          </ActionButton>
          <ActionButton type="button" onClick={toggleShowDowngradingModal}>
            <MdEdit color={theme.gray} />
          </ActionButton>
        </Stack>
      </Stack>

      {task?.downgrading?.weighingLines?.map((item, index) => {
        return item?._destroy ? null : (
          <Stack size="full" key={index}>
            <Cell
              flex={columnsFlex[1] + columnsFlex[2] + columnsFlex[3]}
              style={{ paddingLeft: 0, marginLeft: 0 }}
            >
              <ItemGrid key={item.id}>
                <Stack alignY="center" size="full">
                  <div
                    style={{
                      textAlign: 'left',
                      minWidth: '100%',
                    }}
                  >
                    {item?.containerResourceId?.label}
                  </div>
                </Stack>
                <Stack alignY="center" size="full">
                  <div style={{ textAlign: 'right', minWidth: '100%' }}>
                    {task?.identifiedContainersRequired
                      ? item?.identifier
                      : item?.containerCount}
                  </div>
                </Stack>
                <Stack alignY="center" size="full">
                  <div style={{ textAlign: 'right', minWidth: '100%' }}>
                    {item?.measurement}
                  </div>
                </Stack>
                <Stack alignY="center" size="full">
                  <div
                    style={{
                      textAlign: 'right',
                      minWidth: '100%',
                    }}
                  >
                    {task?.productResourceUnit}
                  </div>
                </Stack>
              </ItemGrid>
            </Cell>
            <Cell flex={columnsFlex[4]} />
          </Stack>
        );
      })}

      <Stack style={{ padding: 0 }} size="full">
        <Cell
          flex={columnsFlex[1] + columnsFlex[2] + columnsFlex[3]}
          style={{ paddingLeft: 0 }}
        >
          <ItemGrid>
            <Stack size="full" alignY="center" alignX="space-between" />
            {task?.productResourceUnit !== 'un' && (
              <>
                <Stack
                  alignY="center"
                  alignX="right"
                  style={{
                    fontSize: '14px',
                  }}
                >
                  Tare totale :
                </Stack>
                <Stack size="full" alignY="center">
                  <div
                    style={{
                      textAlign: 'right',
                      minWidth: '100%',
                    }}
                  >
                    {task?.downgrading?.totalTare}
                  </div>
                </Stack>
                <Stack alignX="right" size="full" alignY="center">
                  {task?.productResourceUnit}
                </Stack>
              </>
            )}
          </ItemGrid>
        </Cell>
        <Cell flex={columnsFlex[4]} />
      </Stack>

      <Stack style={{ padding: 0 }} size="full">
        <Cell
          flex={columnsFlex[1] + columnsFlex[2] + columnsFlex[3]}
          style={{ paddingLeft: 0 }}
        >
          <ItemGrid>
            <Stack size="full" alignY="center" alignX="space-between" />
            {task?.productResourceUnit !== 'un' && (
              <>
                <Stack alignX="right" alignY="center" marginRight={-0.125}>
                  <strong>Total net tare déduite :</strong>
                </Stack>
                <Stack size="full" alignY="center">
                  <div
                    style={{
                      textAlign: 'right',
                      minWidth: '100%',
                      fontSize: '18px',
                    }}
                  >
                    <strong>{task?.downgrading?.totalMeasurement}</strong>
                  </div>
                </Stack>
                <Stack
                  alignY="center"
                  alignX="right"
                  style={{
                    fontSize: '18px',
                  }}
                >
                  <strong>{task?.productResourceUnit}</strong>
                </Stack>
              </>
            )}
          </ItemGrid>
        </Cell>
        <Cell flex={columnsFlex[4]} />
      </Stack>
      <strong>Description</strong>
      <Stack
        style={{
          whiteSpace: 'pre-wrap',
          textAlign: 'left',
          padding: `${theme.spacing(0.5)} 0`,
        }}
      >
        {task?.downgrading?.description}
      </Stack>
    </DowngradingWrapper>
  );
};

export default DowngradingDisplay;
