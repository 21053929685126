import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormField,
  FormLabel,
  FormError,
  Textarea,
} from '@tymate/elise/ui/forms';

const TextareaField = ({
  name,
  control,
  label,
  disabled,
  placeholder,
  noMargin,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormField noMargin={noMargin}>
          {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
          <Textarea
            id={name}
            hasError={fieldState.error}
            disabled={disabled}
            placeholder={placeholder}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            {...props}
          />
          {fieldState.error && fieldState.error.message && (
            <FormError role="alert">{fieldState.error.message}</FormError>
          )}
        </FormField>
      )}
    />
  );
};

export default TextareaField;
