import React from 'react';
import { useWatch } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { Barcode } from '@tymate/elise/components';
import { Stack } from '@tymate/margaret';
import { CardSubtitle } from '@tymate/elise/ui/card';
import { formatDate } from '@tymate/elise/utils';
import { Status, Card } from 'ui';
import { getWorkOrderStatus } from 'utils';

const SubTitleInfo = styled.div`
  color: ${({ theme }) => theme.textLighten};
`;

const BarCodeInfo = styled.div`
  text-align: end;
  z-index: 1;
`;

const WeighingSummary = ({ data, control }) => {
  const theme = useTheme();
  const tasks = useWatch({
    control,
    name: `weighingTasks`,
  });
  const status = getWorkOrderStatus(tasks);

  return (
    <Card lightPadding size="full" height="auto">
      <Stack alignX="space-between">
        <Stack gutterSize={0.75} direction="column">
          <Stack gutterSize={1} alignY="baseline">
            <CardSubtitle noMargin>{data?.collectionPoint?.name}</CardSubtitle>
            <SubTitleInfo>(ID {data?.collectionPoint?.id})</SubTitleInfo>
          </Stack>
          <Stack direction="column">
            <div>{data?.collectionPoint?.address}</div>
            <Stack gutterSize={0.5}>
              <div>{data?.collectionPoint?.zipCode}</div>
              <div>{data?.collectionPoint?.city}</div>
            </Stack>
          </Stack>
          <Stack gutterSize={0.5}>
            <div style={{ color: theme.textLight }}>Date de passage: </div>
            <div>
              {formatDate(
                new Date(data?.date || data?.workOrderDate),
                'dd/MM/yyyy',
              )}
            </div>
          </Stack>
        </Stack>
        <Stack direction="column" alignX="flex-end">
          <Status
            backgroundColor={`${status?.color}Tag`}
            color={`${status?.color}Label`}
          >
            {status?.label}
          </Status>
          <div style={{ margin: '15px -10px -10px 0' }}>
            <Barcode value={data?.id} />
          </div>
          <BarCodeInfo>{data?.workOrderId ?? data.id}</BarCodeInfo>
        </Stack>
      </Stack>
    </Card>
  );
};

export default WeighingSummary;
