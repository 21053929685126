import React, { Suspense, useContext } from 'react';
import { Page, Content } from '@tymate/elise/ui/layout';
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from './components/Header';
import Weighings from './containers/Weighings';
import Entries from './containers/Entries';
import { AuthContext } from './contexts';
import useLocationBlocker from '@tymate/elise/hooks/useLocationBlocker';

const App = () => {
  const { accessToken } = useContext(AuthContext);
  useLocationBlocker();

  if (!Boolean(accessToken)) {
    return <Redirect to="/connexion" />;
  }

  return (
    <Page>
      <Header />
      <Content>
        <Suspense fallback={<div />}>
          <Switch>
            <Route path="/pesees" component={Weighings} />
            <Route path="/saisies" component={Entries} />
            <Redirect to="/pesees" />
          </Switch>
        </Suspense>
      </Content>
    </Page>
  );
};

export default App;
