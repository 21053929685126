import React, { useReducer, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import styled, { useTheme } from 'styled-components';
import { Stack } from '@tymate/margaret';
import TextField from '@tymate/elise/components/Fields/TextField';
import SelectSearchQueryField from 'components/Fields/SelectSearchQueryField';
import { ActionButton, Button } from '@tymate/elise/ui';
import { MdClose, MdEdit } from 'react-icons/md';
import { useFieldArray, useWatch, useFormState } from 'react-hook-form';
import { Row, Cell, Status } from 'ui';
import { getResources } from 'api/references';
import { sum } from 'lodash';
import DescendantTaskRow from './DescendantTaskRow';
import { useEffectOnce } from 'react-use';
import EditTare from './EditTare';
import EditStatus from './EditStatus';
import NewDowngrading from './NewDowngrading';
import {
  calculateWeighingLineEstimatedTare,
  getStatusByValue,
  getVariantFromAlertAndDirty,
  STATUS_AUTOMATIC,
  WORK_ORDER_TASK_STATUS,
} from 'utils';
import DowngradeRow from './DowngradeRow';
import DeleteDowngrade from './DeleteDowngrade';
import { Text } from 'ui/typography';
import { useLocation } from 'react-router-dom';

const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 5fr 5fr 3fr 1fr 24px;
  gap: 0px 12px;
  min-width: 100%;
`;

const Wrapper = styled(Stack).attrs({
  size: 'full',
  direction: 'column',
})`
  border-top: 1px solid ${({ theme }) => theme.separator};
`;

const calculateEstimatedTotalTare = (
  defaultWeighingLines,
  weighingLines,
  totalTare,
  identifiedContainersRequired,
) => {
  const hasDefaultWeighingLinesQuantityChanged = defaultWeighingLines.some(
    (weighingLine, index) =>
      weighingLine.containerCount !== weighingLines[index]?.containerCount,
  );

  if (hasDefaultWeighingLinesQuantityChanged) {
    return sum(
      weighingLines?.map(weighingLine =>
        calculateWeighingLineEstimatedTare({
          ...weighingLine,
          identifiedContainersRequired,
        }),
      ),
    );
  }
  return (
    sum(
      weighingLines?.map(weighingLine =>
        weighingLine.id || weighingLine.orderLineResourceId
          ? 0
          : calculateWeighingLineEstimatedTare({
              ...weighingLine,
              identifiedContainersRequired,
            }),
      ),
    ) + Number(totalTare)
  );
};

const NetTotalWithoutTare = ({
  indexRow,
  productResourceUnit,
  control,
  setValue,
  fields,
  identifiedContainersRequired,
  totalTare,
  totalMeasurementAlert,
  defaultWeighingLines,
}) => {
  const [isFirstRun, setIsFirstRun] = useState(true);
  const tare = useWatch({
    control: control,
    name: `weighingTasks[${indexRow}].totalTare`,
  });
  const weighingLines = useWatch({
    control: control,
    name: fields?.map(
      (_, index) => `weighingTasks[${indexRow}].weighingLines[${index}]`,
    ),
  });
  const estimatedTare = calculateEstimatedTotalTare(
    defaultWeighingLines,
    weighingLines,
    totalTare,
    identifiedContainersRequired,
  );

  const totalMeasurement = sum(
    weighingLines?.map(({ measurement }) => Number(measurement || 0)),
  );
  const totalMeasurementMinusTare = (
    totalMeasurement - (productResourceUnit === 'un' ? 0 : Number(tare || 0))
  ).toFixed(1);

  useDeepCompareEffect(() => {
    if (isFirstRun) {
      setIsFirstRun(false);
    } else if (tare !== estimatedTare) {
      setValue(`weighingTasks[${indexRow}].totalTare`, estimatedTare);
    }
  }, [{ estimatedTare }]);

  return (
    <>
      <div />
      <Stack alignX="right" alignY="center" marginRight={-0.125}>
        <strong>
          {productResourceUnit === 'un'
            ? 'Total :'
            : 'Total net tare déduite :'}
        </strong>
      </Stack>
      <Stack size="full" alignX="right" alignY="center" paddingHorizontal={0.5}>
        <Text
          fontWeight="bold"
          fontSize="18"
          color={totalMeasurementAlert ? 'error' : undefined}
        >
          {totalMeasurementMinusTare}
        </Text>
      </Stack>

      <Stack alignY="center" alignX="right">
        <Text
          fontWeight="bold"
          fontSize="18"
          color={totalMeasurementAlert ? 'error' : undefined}
        >
          {productResourceUnit}
        </Text>
      </Stack>
      <div />
    </>
  );
};

const WeighingDataTableRow = ({
  vrac,
  indexRow,
  columnsFlex,
  defaultTask,
  isControl,
  methods,
}) => {
  const { control, getValues, setValue } = methods;
  const location = useLocation();
  const theme = useTheme();
  const task = getValues(`weighingTasks[${indexRow}]`);
  const [isFirstRunMeasurements, setIsFirstRunMeasurements] = useState(true);
  const [isFirstRunStatus, setIsFirstRunStatus] = useState(true);
  const [hasManuallyChangeStatus, setHasManuallyChangeStatus] = useState(false);
  const [showTareModal, toggleShowTareModal] = useReducer(
    state => !state,
    false,
  );
  const [showStatusModal, toggleShowStatusModal] = useReducer(
    state => !state,
    false,
  );
  const [showDowngradingModal, toggleShowDowngradingModal] = useReducer(
    state => !state,
    false,
  );
  const [showDeleteDowngradeModal, toggleShowDeleteDowngradeModal] = useReducer(
    state => !state,
    false,
  );
  const [watchedWeighingLines, status, billingMeasurement, reportWeighings] =
    useWatch({
      control: control,
      name: [
        `weighingTasks[${indexRow}].weighingLines`,
        `weighingTasks[${indexRow}].status`,
        `weighingTasks[${indexRow}].billingMeasurement`,
        `weighingTasks[${indexRow}].reportWeighings`,
      ],
    });
  const measurements = !['unsto', 'pres'].includes(task?.billingUnit?.value)
    ? [
        ...watchedWeighingLines?.map(({ measurement }) => measurement),
        ...reportWeighings?.map(({ measurement }) => measurement),
        billingMeasurement,
      ]
    : [
        ...watchedWeighingLines?.map(({ measurement }) => measurement),
        ...reportWeighings?.map(({ measurement }) => measurement),
      ];

  const { append, remove, fields } = useFieldArray({
    control: control,
    name: `weighingTasks[${indexRow}].weighingLines`,
  });

  const statusInfo = getStatusByValue(status);

  const handleAddALine = () => {
    append({
      orderLineResourceId: null,
      containerResourceId: null,
      container: '',
      containerCount: null,
      identifier: '',
      measurement: null,
    });
  };

  const isAdditionalLine = item => !item.container;

  const handleRemove = index => {
    remove(index);
  };

  useEffectOnce(() => {
    (task?.reportingList || []).forEach((item, indexReporting) => {
      if (item.unit.value === 'pres') {
        setValue(
          `weighingTasks[${indexRow}].reportingList.${indexReporting}.value`,
          1,
        );
      }
    });

    if (fields?.length === 0 && task?.productResourceUnit !== 'un') {
      append({
        orderLineResourceId: null,
        containerResourceId: {
          value: vrac?.id,
          name: vrac?.name,
          tare: vrac?.tare,
        },
        container: '',
        containerCount: 1,
        identifier: '',
        measurement: '',
        cannotBeDeleted: true,
      });
    }
  });

  useDeepCompareEffect(() => {
    if (isFirstRunStatus) {
      setIsFirstRunStatus(false);
    } else if (status && !STATUS_AUTOMATIC.includes(status)) {
      let removeIds = [];
      setHasManuallyChangeStatus(true);
      watchedWeighingLines.forEach((watchedWeighingLine, index) => {
        if (watchedWeighingLine?.container) {
          ['identifier', 'measurement', 'containerCount'].forEach(input =>
            setValue(
              `weighingTasks[${indexRow}].weighingLines[${index}].${input}`,
              null,
            ),
          );
        } else {
          removeIds.push(index);
        }
      });

      remove(removeIds);

      task.reportWeighings.forEach((_, indexReporting) => {
        setValue(
          `weighingTasks[${indexRow}].reportWeighings[${indexReporting}].measurement`,
          null,
        );
      });

      if (!['unsto', 'pres'].includes(task?.billingUnit?.value)) {
        setValue(`weighingTasks[${indexRow}].billingMeasurement`, null);
      }

      ['downgrading', 'totalTare', 'totalMeasurement'].forEach(input =>
        setValue(`weighingTasks[${indexRow}].${input}`, null),
      );
    }
  }, [{ status }]);

  useDeepCompareEffect(() => {
    if (
      isFirstRunMeasurements &&
      (location.pathname.includes('/controle-des-odm') ||
        location.pathname.includes('/modifier'))
    ) {
      setIsFirstRunMeasurements(false);
    } else if (
      measurements.every(measurement => Boolean(measurement)) &&
      !hasManuallyChangeStatus
    ) {
      setValue(`weighingTasks[${indexRow}].status`, STATUS_AUTOMATIC[0]);
      (task?.descendantWeighingTasks || []).forEach((_, index) =>
        setValue(
          `weighingTasks[${indexRow}].descendantWeighingTasks[${index}].status`,
          STATUS_AUTOMATIC[0],
        ),
      );
    } else if (
      measurements.some(measurement => Boolean(measurement)) &&
      !hasManuallyChangeStatus
    ) {
      setValue(`weighingTasks[${indexRow}].status`, STATUS_AUTOMATIC[1]);
    }
    if (hasManuallyChangeStatus) {
      setHasManuallyChangeStatus(false);
    }
  }, [{ measurements }]);

  const { dirtyFields } = useFormState({ control: control });

  return (
    <Wrapper>
      {showTareModal && (
        <EditTare
          toggleShow={toggleShowTareModal}
          indexRow={indexRow}
          setValue={setValue}
          defaultValue={task?.totalTare}
        />
      )}
      {showStatusModal && (
        <EditStatus
          toggleShow={toggleShowStatusModal}
          options={WORK_ORDER_TASK_STATUS}
          handleSubmit={({ status }) =>
            setValue(`weighingTasks[${indexRow}].status`, status)
          }
          defaultValue={statusInfo}
          size="large"
        />
      )}
      {showDowngradingModal && (
        <NewDowngrading
          toggleShow={toggleShowDowngradingModal}
          formPath={`weighingTasks[${indexRow}].downgrading`}
          setValue={setValue}
          task={task}
        />
      )}
      {showDeleteDowngradeModal && (
        <DeleteDowngrade
          toggleShow={toggleShowDeleteDowngradeModal}
          indexRow={indexRow}
          setValue={setValue}
          id={task?.downgrading?.id}
          getValues={getValues}
        />
      )}
      <Row>
        <Cell flex={columnsFlex[0]}>
          <Stack
            direction="column"
            size="full"
            style={{ textAlign: 'left' }}
            gutterSize={0.5}
          >
            <strong>{task.title}</strong>
            <div style={{ color: theme.textLighten }}>{task.ref}</div>
            <Stack alignY="center" gutterSize={0.5}>
              <Status
                rounded
                backgroundColor={`${statusInfo?.color}Tag`}
                color={`${statusInfo?.color}Label`}
              >
                {statusInfo?.label}
              </Status>
              <ActionButton type="button" onClick={toggleShowStatusModal}>
                <MdEdit color={theme.grayLight} />
              </ActionButton>
            </Stack>
            {(!(
              task?.downgrading &&
              task?.downgrading?.weighingLines?.length !== 0
            ) ||
              task?.downgrading?._destroy) && (
              <Button
                variant="phantom"
                size="small"
                onClick={() => {
                  toggleShowDowngradingModal();
                }}
                type="button"
              >
                Déclarer un déclassement
              </Button>
            )}
          </Stack>
        </Cell>
        <Cell
          flex={columnsFlex[1] + columnsFlex[2] + columnsFlex[3]}
          style={{ paddingLeft: 0, marginLeft: 0 }}
        >
          <Stack direction="column" size="full" gutterSize={1}>
            {fields?.map((item, index) => {
              return (
                <ItemGrid key={item?.id}>
                  {isAdditionalLine(item) ? (
                    <Stack size="full">
                      <SelectSearchQueryField
                        name={`weighingTasks[${indexRow}].weighingLines[${index}].containerResourceId`}
                        labelKey="name"
                        valueKey="id"
                        queryKey={['resources']}
                        queryFn={getResources}
                        params={{ container: true }}
                        placeholder="Contenant..."
                        optionalKeys={['tare']}
                        storeEntireOption
                        initialValueLabel={item?.containerResourceId?.name}
                        onChange={() => {
                          if (
                            watchedWeighingLines?.[index]?.containerCount ===
                            null
                          ) {
                            setValue(
                              `weighingTasks[${indexRow}].weighingLines[${index}].containerCount`,
                              1,
                            );
                          }
                        }}
                      />
                    </Stack>
                  ) : (
                    <Stack
                      size="full"
                      alignY="center"
                      style={{ textAlign: 'left' }}
                    >
                      {task?.identifiedContainersRequired
                        ? item?.containerResourceId?.label
                        : item?.container}
                    </Stack>
                  )}

                  <Stack
                    gutterSize={0.5}
                    size="full"
                    alignY="flex-start"
                    alignX="flex-end"
                  >
                    {task?.identifiedContainersRequired ? (
                      <TextField
                        name={`weighingTasks[${indexRow}].weighingLines[${index}].identifier`}
                        placeholder="Identification"
                        textAlign="right"
                        variant={getVariantFromAlertAndDirty(
                          defaultTask?.weighingLines?.[index]
                            ?.missingIdentifierAlert,
                          dirtyFields?.weighingTasks?.[indexRow]
                            ?.weighingLines?.[index]?.identifier,
                          isControl,
                        )}
                      />
                    ) : (
                      <TextField
                        name={`weighingTasks[${indexRow}].weighingLines[${index}].containerCount`}
                        placeholder="Quantité"
                        textAlign="right"
                        variant={getVariantFromAlertAndDirty(
                          defaultTask?.weighingLines?.[index]
                            ?.containerCountAlert,
                          dirtyFields?.weighingTasks?.[indexRow]
                            ?.weighingLines?.[index]?.containerCount,
                          isControl,
                        )}
                      />
                    )}
                  </Stack>
                  <Stack
                    size="full"
                    alignY="flex-start"
                    style={{ margin: '0px 8px' }}
                  >
                    <TextField
                      name={`weighingTasks[${indexRow}].weighingLines[${index}].measurement`}
                      textAlign="right"
                      variant={getVariantFromAlertAndDirty(
                        defaultTask?.weighingLines?.[index]
                          ?.missingMeasurementAlert,
                        dirtyFields?.weighingTasks?.[indexRow]?.weighingLines?.[
                          index
                        ]?.measurement,
                        isControl,
                      )}
                    />
                  </Stack>
                  <Stack
                    alignX="right"
                    size="full"
                    alignY="flex-start"
                    style={{ paddingTop: '10px' }}
                  >
                    {task?.productResourceUnit}
                  </Stack>
                  <Stack size="full" alignY="center">
                    {isAdditionalLine(item) && !item?.cannotBeDeleted && (
                      <ActionButton
                        type="button"
                        onClick={() => {
                          handleRemove(index);
                        }}
                      >
                        <MdClose />
                      </ActionButton>
                    )}
                  </Stack>
                </ItemGrid>
              );
            })}
            <Stack size="full" alignY="center" alignX="flex-end">
              {['unsto'].includes(task?.billingUnit?.value) &&
                watchedWeighingLines?.length === 0 &&
                task?.billingMethod !== 'fixed' && (
                  <Stack gutterSize={0.5} alignY="center">
                    <Stack style={{ width: '100px' }}>
                      <TextField
                        textAlign="right"
                        name={`weighingTasks[${indexRow}].billingMeasurement`}
                      />
                    </Stack>
                    <Stack
                      alignX="left"
                      style={{ textAlign: 'left', width: '80px' }}
                    >
                      {task?.billingUnit?.label}
                    </Stack>
                  </Stack>
                )}
            </Stack>
            <ItemGrid>
              <Stack size="full" alignY="center" alignX="space-between">
                <Button
                  variant="outline"
                  size="small"
                  onClick={handleAddALine}
                  type="button"
                >
                  Ajouter une ligne
                </Button>
              </Stack>
              {task.productResourceUnit !== 'un' &&
                task?.weighingLines?.length !== 0 && (
                  <>
                    <Stack
                      alignY="center"
                      alignX="right"
                      marginRight={-0.125}
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      Tare totale :
                    </Stack>
                    <Stack style={{ margin: '0 8px' }} size="full">
                      <TextField
                        name={`weighingTasks[${indexRow}].totalTare`}
                        disabled
                        textAlign="right"
                      />
                    </Stack>
                    <Stack size="full" alignY="center" alignX="right">
                      {task?.productResourceUnit}
                    </Stack>
                    <Stack size="full" alignY="center">
                      <ActionButton type="button" onClick={toggleShowTareModal}>
                        <MdEdit color={theme.grayLight} />
                      </ActionButton>
                    </Stack>
                  </>
                )}
            </ItemGrid>
            <ItemGrid>
              {task?.weighingLines?.length !== 0 && (
                <NetTotalWithoutTare
                  indexRow={indexRow}
                  productResourceUnit={task?.productResourceUnit}
                  control={control}
                  setValue={setValue}
                  fields={fields}
                  defaultWeighingLines={defaultTask?.weighingLines}
                  identifiedContainersRequired={
                    defaultTask?.identifiedContainersRequired
                  }
                  totalTare={defaultTask?.totalTare}
                  totalMeasurementAlert={defaultTask?.totalMeasurementAlert}
                />
              )}
            </ItemGrid>
          </Stack>
        </Cell>
        <Cell style={{ minWidth: '210px' }}>
          <Stack
            direction="column"
            gutterSize={1}
            size="full"
            marginLeft={0.25}
          >
            {task?.billingMethod !== 'unit' ||
              (task?.billingMethod === 'unit' &&
                !['unsto', 'pres'].includes(task?.billingUnit?.value) && (
                  <Stack gutterSize={0.5} size="full" alignY="center">
                    <Stack size="full" flex={1}>
                      <TextField
                        textAlign="right"
                        name={`weighingTasks[${indexRow}].billingMeasurement`}
                      />
                    </Stack>
                    <Stack
                      alignX="left"
                      style={{ textAlign: 'left' }}
                      size="full"
                      flex={1}
                    >
                      {task?.billingUnit?.label}
                    </Stack>
                  </Stack>
                ))}
            {task?.reportWeighings?.map((item, indexReporting) => (
              <Stack
                gutterSize={0.5}
                size="full"
                alignY="center"
                key={`reporting[${indexReporting}]`}
              >
                <Stack size="full" flex={1}>
                  <TextField
                    textAlign="right"
                    name={`weighingTasks[${indexRow}].reportWeighings[${indexReporting}].measurement`}
                    variant={getVariantFromAlertAndDirty(
                      defaultTask?.reportWeighings?.[indexReporting]
                        ?.missingMeasurementAlert,
                      dirtyFields?.weighingTasks?.[indexRow]?.reportWeighings?.[
                        indexReporting
                      ]?.measurement,
                      isControl,
                    )}
                  />
                </Stack>
                <Stack
                  alignX="left"
                  style={{ textAlign: 'left' }}
                  size="full"
                  flex={1}
                >
                  {item?.unit?.label}
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Cell>
      </Row>
      {task?.downgrading &&
        !task?.downgrading?._destroy &&
        task?.downgrading?.weighingLines?.length !== 0 && (
          <DowngradeRow
            formPath={`weighingTasks[${indexRow}].downgrading`}
            task={task}
            defaultTask={defaultTask}
            columnsFlex={columnsFlex}
            toggleShowDeleteDowngradeModal={toggleShowDeleteDowngradeModal}
            toggleShowDowngradingModal={toggleShowDowngradingModal}
            isControl={isControl}
            methods={methods}
            indexRow={indexRow}
          />
        )}
      {task?.descendantWeighingTasks?.map((descendantTask, indexDescendant) => (
        <DescendantTaskRow
          key={descendantTask.id}
          descendantTask={task?.descendantWeighingTasks?.[indexDescendant]}
          formKey={`weighingTasks[${indexRow}].descendantWeighingTasks[${indexDescendant}]`}
          methods={methods}
          initialStatus={
            defaultTask?.descendantWeighingTasks?.[indexDescendant]?.status
          }
        />
      ))}
    </Wrapper>
  );
};

export default WeighingDataTableRow;
