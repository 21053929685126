import React from 'react';
import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import scanner from 'images/scanner-bg.svg';

const Wrapper = styled(Stack).attrs({
  direction: 'column',
  alignX: 'center',
  size: 'full',
})`
  position: relative;
  background-size: contain;
  background-repeat: repeat;
  background-position: center center;
  min-height: 100vh;
  padding: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.background};
`;

const Logo = styled.img`
  max-width: 300px;
  margin-top: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

const Card = styled.div`
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 4px 16px 8px rgba(0, 0, 0, 0.12);
  padding: ${({ theme }) => theme.spacing(2.5)}
    ${({ theme }) => theme.spacing(2)};
  width: 100%;
  max-width: 400px;
  position: relative;
`;

const LeftScanner = styled.img.attrs({ src: scanner, alt: '' })`
  position: absolute;
  top: 0;
  left: -20%;
`;

const RightScanner = styled.img.attrs({ src: scanner, alt: '' })`
  position: absolute;
  transform: scaleX(-1);
  bottom: 0;
  right: -5%;
`;

const AuthLayout = ({ logo, children }) => (
  <Wrapper>
    <Logo src={logo} alt="Elise" />
    <LeftScanner />
    <RightScanner />
    <Card>{children}</Card>
  </Wrapper>
);

export default AuthLayout;
