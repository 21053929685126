import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createBrowserHistory } from 'history';
import { theme, GlobalStyles } from '@tymate/elise/ui';
import AppProvider from '@tymate/elise/providers/AppProvider';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { MargaretProvider } from '@tymate/margaret';
import 'sanitize.css';
import AuthProvider from 'providers/AuthProvider';
import Auth from 'containers/Auth';
import { SENTRY_DSN, SENTRY_ENVIRONMENT, API_BASE } from './constants';
import { initSentry } from '@tymate/elise/utils/sentry';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 20,
    },
  },
});

export const history = createBrowserHistory();

initSentry(SENTRY_DSN, SENTRY_ENVIRONMENT, API_BASE, history, 'elise-weighing');

ReactDOM.render(
  <Suspense fallback={<div />}>
    <MargaretProvider theme={theme}>
      <Router history={history}>
        <QueryClientProvider client={client}>
          <AppProvider>
            <GlobalStyles />
            <AuthProvider>
              <Switch>
                <Route path="/connexion" component={Auth} />
                <Route component={App} />
              </Switch>
            </AuthProvider>
          </AppProvider>
        </QueryClientProvider>
      </Router>
    </MargaretProvider>
  </Suspense>,
  document.getElementById('root'),
);

serviceWorker.unregister();
