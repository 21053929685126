import React from 'react';
import { Stack } from '@tymate/margaret';
import { Select } from '@tymate/elise/components';
import { useQuery } from 'react-query';
import { getProductionSites } from 'api/productionSites';
import { useSearchParams } from '@tymate/elise/hooks';

const LocationAwareSelect = ({ options, size = 'auto' }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const productionSiteId = searchParams?.filter?.productionSiteId || '';

  return (
    <Stack alignY="center" gutterSize={0.25}>
      <Select
        onChange={newValue => {
          setSearchParams({
            'filter[productionSiteId]': newValue?.value,
          });
        }}
        value={productionSiteId}
        defaultValue={productionSiteId}
        variant="input"
        hasFixedWidth
        size={size}
        options={options}
      />
    </Stack>
  );
};

const LocationAwareSelectProductionSites = () => {
  const { data: productionSitesData } = useQuery(
    ['productionSites'],
    getProductionSites,
  );
  const productionSites = productionSitesData?.data ?? [];

  return (
    <LocationAwareSelect
      options={[
        { label: 'Tous', value: '' },
        ...productionSites.map(({ id, name }) => ({
          value: String(id),
          label: name,
        })),
      ]}
    />
  );
};

export default LocationAwareSelectProductionSites;
