import React, { useReducer, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { MdSubdirectoryArrowRight, MdEdit } from 'react-icons/md';
import { Stack } from '@tymate/margaret';
import TextField from '@tymate/elise/components/Fields/TextField';
import { ActionButton } from '@tymate/elise/ui';
import { Row, Cell, Status } from 'ui';
import EditStatus from './EditStatus';
import {
  getStatusByValue,
  STATUS_AUTOMATIC,
  WORK_ORDER_TASK_STATUS,
} from 'utils';
import { useWatch } from 'react-hook-form';
import { useDeepCompareEffect } from 'react-use';
import { useLocation } from 'react-router-dom';

const Separator = styled(Stack)`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.separator};
`;

const DescendantTaskRow = ({ descendantTask, formKey, methods }) => {
  const theme = useTheme();
  const location = useLocation();
  const [isFirstRunStatus, setIsFirstRunStatus] = useState(true);
  const { control, setValue } = methods;
  const [isFirstRun, setIsFirstRun] = useState(true);
  const [showStatusModal, toggleShowStatusModal] = useReducer(
    state => !state,
    false,
  );
  const statusInfo = getStatusByValue(descendantTask?.status);

  const [status, billingMeasurement] = useWatch({
    control: control,
    name: [`${formKey}.status`, `${formKey}.billingMeasurement`],
  });

  useDeepCompareEffect(() => {
    if (status && !STATUS_AUTOMATIC.includes(status) && !isFirstRun) {
      setValue(`${formKey}.billingMeasurement`, null);
    } else if (isFirstRun) {
      setIsFirstRun(false);
    }
  }, [{ status }]);

  useDeepCompareEffect(() => {
    if (isFirstRunStatus) {
      setIsFirstRunStatus(false);
    } else if (
      billingMeasurement ||
      (['unsto', 'pres', descendantTask.billingUnitParent?.value].includes(
        descendantTask.billingUnit?.value,
      ) &&
        [('/modifier', '/controle-des-odm')].includes(location.pathname))
    ) {
      setValue(`${formKey}.status`, STATUS_AUTOMATIC[0]);
    }
  }, [{ billingMeasurement }]);

  return (
    <Stack
      direction="column"
      size="full"
      alignX="center"
      gutterSize="0"
      paddingLeft={3}
      paddingRight={0.5}
    >
      <Separator />
      <Row style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <Cell flex={4} style={{ paddingLeft: '0px' }}>
          <div
            style={{
              marginRight: theme.spacing(0.5),
              marginTop: theme.spacing(-0.25),
            }}
          >
            <MdSubdirectoryArrowRight size={20} />
          </div>
          <Stack
            direction="column"
            size="full"
            style={{ textAlign: 'left' }}
            gutterSize={0.25}
          >
            <strong>{descendantTask.title}</strong>
            <div style={{ color: theme.textLighten }}>{descendantTask.ref}</div>
            <Stack alignY="center" gutterSize={0.5}>
              <Status
                rounded
                backgroundColor={`${statusInfo?.color}Tag`}
                color={`${statusInfo?.color}Label`}
              >
                {statusInfo?.label}
              </Status>
              <ActionButton type="button" onClick={toggleShowStatusModal}>
                <MdEdit color={theme.grayLight} />
              </ActionButton>
            </Stack>
          </Stack>
        </Cell>

        <Cell style={{ maxWidth: '210px' }}>
          {!['unsto', 'pres', descendantTask.billingUnitParent?.value].includes(
            descendantTask.billingUnit?.value,
          ) && (
            <Stack
              direction="column"
              gutterSize={1}
              size="full"
              marginLeft={0.25}
            >
              <Stack gutterSize={0.5} size="full" alignY="center">
                <Stack size="full" flex={1}>
                  <TextField name={`${formKey}.billingMeasurement`} />
                </Stack>
                <Stack
                  alignX="left"
                  style={{ textAlign: 'left' }}
                  size="full"
                  flex={1}
                >
                  {descendantTask.billingUnit?.label}
                </Stack>
              </Stack>
            </Stack>
          )}
        </Cell>
      </Row>
      {showStatusModal && (
        <EditStatus
          toggleShow={toggleShowStatusModal}
          options={WORK_ORDER_TASK_STATUS}
          handleSubmit={({ status }) => setValue(`${formKey}.status`, status)}
          defaultValue={statusInfo?.value}
          size="large"
        />
      )}
    </Stack>
  );
};

export default DescendantTaskRow;
