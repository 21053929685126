import React from 'react';
import { sum } from 'lodash';
import { Modal } from '@tymate/elise/components';
import DowngradingForm from 'components/Forms/DowngradingForm';

const NewDowngrading = ({ setValue, toggleShow, formPath, task }) => {
  const isDowngradingToDestroy = data => {
    return data?.weighingLines?.some(weighingLine => weighingLine?.destroy);
  };

  const handleSubmit = data => {
    const sumMeasurement = sum(
      data?.weighingLines.map(({ measurement }) => Number(measurement || 0)),
    );
    const totalMeasurement =
      sumMeasurement -
      (data?.productResourceUnit === 'un' ? 0 : Number(data?.totalTare || 0));
    const formattedData = {
      id: data?.id,
      description: data?.description,
      totalTare: data?.totalTare || '0',
      totalMeasurement,
      _destroy: isDowngradingToDestroy(data),
      weighingLines: data?.weighingLines?.map(weighingLine => {
        return {
          id: weighingLine?.id,
          identifier: weighingLine?.identifier,
          measurement: weighingLine?.measurement,
          orderLineResourceId: weighingLine?.orderLineResourceId?.value,
          containerResourceId: weighingLine?.containerResourceId,
          containerCount: weighingLine?.containerCount,
          _destroy: weighingLine?._destroy,
        };
      }),
    };
    setValue(formPath, formattedData);
    toggleShow();
  };

  return (
    <Modal
      isOpen
      title="Déclassement en DIB"
      onRequestClose={toggleShow}
      variant="large"
    >
      <DowngradingForm
        task={task}
        toggleShow={toggleShow}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default NewDowngrading;
