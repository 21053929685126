import { get } from '.';

export const getServices = ({ params }) =>
  get(`/v1/references/services?sort=name`, { params });

export const getResources = ({ params }) =>
  get(`/v1/references/resources?sort=name`, { params });

export const getReportingUnits = ({ params }) =>
  get(`/v1/references/reporting_units`, { params });
