import React from 'react';
import { Stack } from '@tymate/margaret';
import { Button } from '@tymate/elise/components';
import styled from 'styled-components';
import { Buttons } from '@tymate/elise/ui';
import { useForm, FormProvider } from 'react-hook-form';
import useYupResolver from '@tymate/elise/hooks/useYupResolver';
import * as Yup from 'yup';
import TextField from '@tymate/elise/components/Fields/TextField';
import { validationFloat } from '@tymate/elise/utils/validation';

const TextSecondary = styled.p`
  color: ${({ theme }) => theme.textLighten};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1.1fr 1.1fr 1.1fr;
  grid-template-rows: 0.5fr;
  gap: 1rem 1rem;
  grid-template-areas: 'tare tare tare';
  align-items: baseline;
  justify-items: flex-start;
  max-width: 300px;
`;

const VALIDATION_SCHEMA = Yup.object().shape({
  tare: validationFloat(),
});

const EditTare = ({ onRequestClose, defaultValue, onSubmit }) => {
  const resolver = useYupResolver(VALIDATION_SCHEMA);
  const methods = useForm({
    resolver,
    defaultValues: {
      tare: defaultValue,
    },
  });

  return (
    <FormProvider {...methods}>
      <form>
        <Stack
          direction="column"
          alignY="flex-start"
          gutterSize={2}
          size="full"
        >
          <TextSecondary>
            Attention, vous êtes sur le point de modifier la tare totale de la
            prestation…
          </TextSecondary>
          <Grid>
            <div>Tare totale : </div>
            <TextField name="tare" textAlign="right" />
            <div>Kg</div>
          </Grid>
          <Buttons
            style={{ marginTop: '1.5rem' }}
            alignment="right"
            size="full"
          >
            <Button type="button" variant="outline" onClick={onRequestClose}>
              Annuler
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={methods.handleSubmit(onSubmit)}
            >
              Valider
            </Button>
          </Buttons>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default EditTare;
