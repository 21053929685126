import React, { useEffect, useState } from 'react';
import { Stack } from '@tymate/margaret';
import { Card } from 'ui';
import { CardSubtitle } from '@tymate/elise/ui/card';
import styled, { css, useTheme } from 'styled-components';
import SelectSearchQueryField from 'components/Fields/SelectSearchQueryField';
import { getResources, getServices } from 'api/references';
import TextField from '@tymate/elise/components/Fields/TextField';
import TextareaField from '@tymate/elise/components/Fields/TextareaField';
import { Button } from '@tymate/elise/components';
import IcTrash from '@tymate/elise/icons/IcTrash';
import DeleteExtraWeighingTaskModal from './DeleteExtraWeighingTaskModal';

const Wrapper = styled(Stack).attrs({
  size: 'full',
  direction: 'column',
})``;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 1.5fr 1.5fr 1fr 0.5fr;
  grid-template-rows: 0.5fr 0.5fr 0.5fr 3fr;
  gap: 0.75rem 0.75rem;
  grid-template-areas:
    'services resources container . totalMeasurement totalMeasurementUnit'
    '. . . tareLabel totalTare totalTareUnit'
    '. . . totalLabel billingMeasurement billingMeasurementUnit'
    'description description description description description description';
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const Label = styled.div`
  align-self: center;
  justify-self: center;

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}
`;

const WrapperCell = styled(Stack).attrs({ gutterSize: 1 })`
  width: 100%;

  ${({ gridArea }) =>
    gridArea &&
    css`
      grid-area: ${gridArea};
    `}

  ${({ alignX }) =>
    alignX &&
    css`
      justify-self: ${alignX};
    `}
    
    ${({ alignY }) =>
    alignY &&
    css`
      align-self: ${alignY};
    `}
`;

const ExtraWeighingTask = ({
  indexRow,
  setValue,
  isControl,
  remove,
  update,
  extraWeighingTask,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const productResourceUnit = extraWeighingTask?.resourceId?.unit || '';

  const totalMeasurement =
    Number(extraWeighingTask?.measurement) -
    (productResourceUnit === 'un'
      ? 0
      : Number(extraWeighingTask?.totalTare || 0));

  useEffect(() => {
    setValue(
      `extraWeighingTasks[${indexRow}].totalMeasurement`,
      totalMeasurement,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalMeasurement]);

  const handleDelete = () => {
    if (extraWeighingTask?.id) {
      update(indexRow, { ...extraWeighingTask, _destroy: true });
    } else {
      remove(indexRow);
    }

    setOpen(false);
  };

  return (
    <Card
      style={{
        backgroundColor: isControl ? theme.errorLight : theme.grayLighter,
      }}
    >
      <Wrapper>
        <Stack alignY="center" alignX="space-between" size="full">
          <CardSubtitle noMargin>Prestation non programmée</CardSubtitle>
          <Button
            icon={<IcTrash />}
            type="button"
            style={{ color: theme.gray }}
            onClick={() => setOpen(true)}
          />
        </Stack>
        <Grid>
          <WrapperCell gridArea="services">
            <SelectSearchQueryField
              name={`extraWeighingTasks[${indexRow}].serviceId`}
              queryKey={['services']}
              queryFn={getServices}
              placeholder="Prestation..."
              storeEntireOption
              initialValueLabel={extraWeighingTask?.serviceId?.label}
            />
          </WrapperCell>
          <WrapperCell gridArea="resources">
            <SelectSearchQueryField
              name={`extraWeighingTasks[${indexRow}].resourceId`}
              queryKey={['resourcesUnit']}
              queryFn={getResources}
              placeholder="Gisement..."
              optionalKeys={['unit']}
              storeEntireOption
              initialValueLabel={extraWeighingTask?.resourceId?.label}
            />
          </WrapperCell>
          <WrapperCell gridArea="container">
            <SelectSearchQueryField
              name={`extraWeighingTasks[${indexRow}].containerResourceId`}
              queryKey={['resourcesContainer']}
              queryFn={getResources}
              params={{ container: true }}
              placeholder="Contenant..."
              initialValueLabel={extraWeighingTask?.containerResourceId?.label}
              storeEntireOption
              optionalKeys={['tare']}
              onChange={({ tare }) => {
                setValue(`extraWeighingTasks[${indexRow}].totalTare`, tare);
              }}
            />
          </WrapperCell>
          <WrapperCell gridArea="tareLabel" alignX="right" alignY="center">
            <Label textAlign="right">Total tare :</Label>
          </WrapperCell>
          <WrapperCell gridArea="totalMeasurement">
            <TextField
              name={`extraWeighingTasks[${indexRow}].measurement`}
              textAlign="right"
            />
          </WrapperCell>
          <WrapperCell
            gridArea="totalMeasurementUnit"
            alignX="left"
            alignY="center"
          >
            <Label textAlign="left">{productResourceUnit}</Label>
          </WrapperCell>
          <WrapperCell gridArea="totalTare">
            <TextField
              name={`extraWeighingTasks[${indexRow}].totalTare`}
              textAlign="right"
            />
          </WrapperCell>
          <WrapperCell gridArea="totalTareUnit" alignX="left" alignY="center">
            <Label textAlign="left">{productResourceUnit}</Label>
          </WrapperCell>
          <WrapperCell gridArea="totalLabel" alignY="center" alignX="right">
            <Label textAlign="right">
              <strong>
                {productResourceUnit === 'un'
                  ? 'Total :'
                  : 'Total net tare déduite :'}
              </strong>
            </Label>
          </WrapperCell>
          <WrapperCell
            gridArea="billingMeasurement"
            alignY="center"
            alignX="right"
          >
            <Label
              textAlign="right"
              style={{
                marginRight: theme.spacing(),
                fontSize: '18px',
              }}
            >
              <strong>{totalMeasurement || 0}</strong>
            </Label>
          </WrapperCell>
          <WrapperCell
            gridArea="billingMeasurementUnit"
            alignX="left"
            alignY="center"
          >
            <Label
              textAlign="left"
              style={{
                fontSize: '18px',
              }}
            >
              <strong>{productResourceUnit}</strong>
            </Label>
          </WrapperCell>
          <WrapperCell gridArea="description">
            <TextareaField
              label="Description"
              name={`extraWeighingTasks[${indexRow}].description`}
              minRows={5}
              maxRows={5}
            />
          </WrapperCell>
        </Grid>
      </Wrapper>
      <DeleteExtraWeighingTaskModal
        handleDelete={handleDelete}
        setOpen={setOpen}
        open={open}
      />
    </Card>
  );
};

export default ExtraWeighingTask;
