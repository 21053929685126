import React from 'react';
import { Stack } from '@tymate/margaret';
import { Container, Title } from '@tymate/elise/ui';
import styled, { useTheme } from 'styled-components';
import LocationAwareProductionSites from 'components/LocationAwareProductionSites';

const Wrapper = styled(Stack).attrs({
  size: 'full',
  direction: 'column',
  gutterSize: 1,
})`
  background-color: ${({ theme }) => theme.white};
  border-bottom: 1px solid ${({ theme }) => theme.separator};
`;

const SubHeader = () => {
  const theme = useTheme();

  return (
    <Wrapper>
      <Container
        variant="main"
        size="large"
        style={{
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(),
        }}
      >
        <Stack size="full" alignX="space-between" alignY="baseline">
          <Title>Toutes les pesées</Title>
          <LocationAwareProductionSites size={285} />
        </Stack>
      </Container>
    </Wrapper>
  );
};

export default SubHeader;
