import React, { useContext, useEffect } from 'react';
import { LoginForm } from '@tymate/elise/components';
import { signIn } from 'api/auth';
import { AuthContext } from 'contexts';
import { history } from 'index';
import logo from 'images/logo.png';
import AuthLayout from 'components/AuthLayout';

const Auth = () => {
  const { onUpdate, accessToken } = useContext(AuthContext);

  const handleSignIn = async (values, { setStatus }) => {
    try {
      const { data } = await signIn(values);

      onUpdate(data);
    } catch (err) {
      setStatus(err?.response?.data?.error);
    }
  };

  useEffect(() => {
    if (Boolean(accessToken)) {
      history.push('/');
    }
  }, [accessToken]);

  return (
    <AuthLayout logo={logo}>
      <LoginForm onSubmit={handleSignIn} />
    </AuthLayout>
  );
};

export default Auth;
