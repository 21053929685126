import React from 'react';
import { Stack } from '@tymate/margaret';
import { Button } from '@tymate/elise/components';
import SegmentedControlField from '@tymate/elise/components/Fields/SegmentedControlField';
import styled from 'styled-components';
import { Buttons } from '@tymate/elise/ui';
import { useForm, FormProvider } from 'react-hook-form';

const TextSecondary = styled.p`
  color: ${({ theme }) => theme.textLight};
`;

const StatusForm = ({ options, onSubmit, onClose, defaultValue, editAll }) => {
  const methods = useForm({
    defaultValues: {
      status: defaultValue,
    },
  });

  return (
    <FormProvider {...methods}>
      <form>
        <Stack
          direction="column"
          alignY="flex-start"
          gutterSize={1}
          size="full"
        >
          <TextSecondary>
            {editAll ? (
              <p>
                Attention! En modifiant les statuts des prestations, toutes les
                données saisies seront supprimées.
              </p>
            ) : (
              <p>
                Attention! En modifiant le statut, toutes les données saisies
                seront supprimées.
              </p>
            )}
          </TextSecondary>
          <strong>Choisir le statut</strong>
          <SegmentedControlField
            name="status"
            options={options}
            setValue={methods.setValue}
            noMargin
          />
          <Buttons
            style={{ marginTop: '1.5rem' }}
            alignment="right"
            size="full"
          >
            <Button
              type="button"
              isLoading={methods.formState.isSubmitting}
              variant="outline"
              onClick={onClose}
            >
              Annuler
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={!methods?.formState?.isDirty}
            >
              Valider
            </Button>
          </Buttons>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default StatusForm;
