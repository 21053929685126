import React from 'react';
import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import { Cell, ExtraWeighingLineRow } from 'ui/entries';
import { Text } from 'ui/typography';

const Wrapper = styled(Stack).attrs({
  size: 'full',
  direction: 'column',
  gutterSize: 0.25,
})`
  border-top: 1px solid ${({ theme }) => theme.separator};
  background-color: ${({ theme }) => theme.primaryLighter};
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
`;

const ExtraWeighingTaskRow = ({ task }) => {
  const measurement =
    Number(task?.totalMeasurement || 0) + Number(task?.totalTare || 0);

  return (
    <Wrapper>
      <Text fontWeight="bold" fontSize={18}>
        Prestation non programmée
      </Text>

      <Stack size="full" direction="column">
        <ExtraWeighingLineRow>
          <Cell gridArea="services">
            <Text>{task?.service?.name}</Text>
          </Cell>
          <Cell gridArea="material">
            <Text>{task?.resource?.name}</Text>
          </Cell>
          <Cell gridArea="container">
            <Text>{task?.containerResource?.name}</Text>
          </Cell>
          <Cell gridArea="weight">
            <Text textAlign="end">
              {measurement} {measurement ? task?.resource?.unit : ''}
            </Text>
          </Cell>
        </ExtraWeighingLineRow>

        {task?.resource?.unit !== 'un' && (
          <ExtraWeighingLineRow>
            <Cell gridArea="label">
              <Text textAlign="end" color="textLight">
                Total tare :
              </Text>
            </Cell>
            <Cell gridArea="weight">
              <Text textAlign="end" color="textLight">
                {task?.totalTare} {task?.totalTare ? task?.resource?.unit : ''}
              </Text>
            </Cell>
          </ExtraWeighingLineRow>
        )}

        <ExtraWeighingLineRow>
          <Cell gridArea="label">
            <Text textAlign="end" fontWeight="bold">
              {task?.resource?.unit === 'un'
                ? 'Total :'
                : 'Total net tare déduite :'}
            </Text>
          </Cell>
          <Cell gridArea="weight">
            <Text textAlign="end" fontWeight="bold">
              {task?.totalMeasurement}{' '}
              {task?.totalMeasurement ? task?.resource?.unit : ''}
            </Text>
          </Cell>
        </ExtraWeighingLineRow>
      </Stack>

      {task.description && (
        <>
          <Text fontWeight="bold" fontSize={16}>
            Description
          </Text>
          <Text>{task.description}</Text>
        </>
      )}
    </Wrapper>
  );
};

export default ExtraWeighingTaskRow;
