import React from 'react';
import DowngradingDisplay from './DowngradingDisplay';
import { Cell } from 'ui';
import { Stack } from '@tymate/margaret';
import DowngradingDisplayInControl from './DowngradingDisplayInControl';

const DowngradeRow = ({ isControl, ...props }) => {
  const { columnsFlex } = props;
  return (
    <Stack size="full">
      <Cell flex={props?.columnsFlex?.[0]} />
      <Cell
        flex={
          columnsFlex?.[1] +
          columnsFlex?.[2] +
          columnsFlex?.[3] +
          columnsFlex?.[4]
        }
        style={{ paddingTop: 0 }}
      >
        {isControl ? (
          <DowngradingDisplayInControl {...props} />
        ) : (
          <DowngradingDisplay {...props} />
        )}
      </Cell>
    </Stack>
  );
};

export default DowngradeRow;
