import React, { useContext } from 'react';
import {
  HeaderWrapper,
  HeaderLogo,
  HeaderLogoLink,
  MainNav,
  MainNavItem,
  MainNavItemLink,
} from '@tymate/elise/ui/layout';
import UserDropdown from '@tymate/elise/components/UserDropdown';
import logo from '../images/logo.png';
import { AuthContext } from 'contexts';

const Header = () => {
  const { user, onReset } = useContext(AuthContext);

  return (
    <HeaderWrapper>
      <HeaderLogoLink to="/">
        <HeaderLogo src={logo} />
      </HeaderLogoLink>

      <MainNav>
        <MainNavItem>
          <MainNavItemLink to="/pesees">Toutes les pesées</MainNavItemLink>
        </MainNavItem>
        <MainNavItem>
          <MainNavItemLink to="/saisies">Contrôle de saisies</MainNavItemLink>
        </MainNavItem>
      </MainNav>
      <UserDropdown
        user={user}
        onReset={onReset}
        app="pesee"
        showProfile={false}
      />
    </HeaderWrapper>
  );
};

export default Header;
