import React from 'react';
import { Modal } from '@tymate/elise/components';
import StatusForm from 'components/Forms/StatusForm';

const EditStatus = ({
  defaultValue,
  toggleShow,
  options,
  handleSubmit,
  size = 'medium',
  editAll,
}) => {
  const title = editAll ? 'Modifier tous les statuts' : 'Modifier le statut';
  return (
    <Modal isOpen title={title} onRequestClose={toggleShow} variant={size}>
      <StatusForm
        options={options}
        onClose={toggleShow}
        onSubmit={value => {
          handleSubmit(value);
          toggleShow();
        }}
        defaultValue={defaultValue}
        editAll={editAll}
      />
    </Modal>
  );
};

export default EditStatus;
