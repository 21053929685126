import React, { useReducer } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import useYupResolver from '@tymate/elise/hooks/useYupResolver';
import { Button, Buttons } from '@tymate/elise/ui';
import WeighingDataTable from 'components/Weighings/DataTable';
import { Stack } from '@tymate/margaret';
import styled, { useTheme } from 'styled-components';
import { ORDER_STATUS, WORK_ORDER_TASK_STATUS } from 'utils';
import EditStatus from 'components/Weighings/EditStatus';
import ExtraWeighingTasks from 'components/Weighings/ExtraWeighingTasks';
import { useHistory, useLocation } from 'react-router-dom';
import WeighingSummary from 'components/Weighings/Summary';
import { validationFloat } from '@tymate/elise/utils/validation';
import { ERRORS } from '@tymate/elise/utils';

export const FooterWrapper = styled(Stack).attrs({
  alignY: 'center',
  gutterSize: 2,
})`
  position: fixed;
  left: 0;
  bottom: 0;
  height: 80px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.12);
  padding: ${({ theme }) => theme.spacing()};
`;

const VALIDATION_SCHEMA = Yup.object().shape({
  weighingTasks: Yup.array().of(
    Yup.object().shape({
      weighingLines: Yup.array().of(
        Yup.object().shape({
          measurement: validationFloat(),
        }),
      ),
      billingMeasurement: validationFloat(),
      reportWeighings: Yup.array().of(
        Yup.object().shape({ measurement: validationFloat() }),
      ),
      totalTare: validationFloat(),
      totalMeasurement: validationFloat(),
      descendantWeighingTasks: Yup.array().of(
        Yup.object().shape({ measurement: validationFloat() }),
      ),
      downgrading: Yup.object()
        .shape({
          totalTare: validationFloat(),
          weighingLines: Yup.array().of(
            Yup.object().shape({
              measurement: validationFloat(),
              containerCount: validationFloat(),
            }),
          ),
        })
        .nullable(true),
    }),
  ),
  extraWeighingTasks: Yup.array().of(
    Yup.object().shape({
      resourceId: Yup.mixed().nullable(true),
      totalMeasurement: validationFloat().required(ERRORS.REQUIRED),
      description: Yup.string().when('resourceId', resourceId => {
        return resourceId
          ? Yup.string()
          : Yup.string().required(ERRORS.REQUIRED);
      }),
    }),
  ),
});

const WeighingForm = ({
  onSubmit,
  summaryData,
  workOrderId,
  tasks,
  extraTasks = [],
  isControl,
}) => {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const [isStatusEditable, toggleStatusEditable] = useReducer(
    state => !state,
    false,
  );
  const resolver = useYupResolver(VALIDATION_SCHEMA);
  const methods = useForm({
    resolver,
    defaultValues: {
      workOrderId,
      status: ORDER_STATUS[0].value,
      weighingTasks: tasks,
      extraWeighingTasks: extraTasks,
    },
  });

  const handleSubmitStatus = ({ status }) =>
    tasks.forEach((task, indexRow) => {
      methods.setValue(`weighingTasks[${indexRow}].status`, status);
      task.descendantWeighingTasks.forEach((_, indexDescendant) => {
        methods.setValue(
          `weighingTasks[${indexRow}].descendantWeighingTasks[${indexDescendant}].status`,
          status,
        );
      });
    });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        style={{ minWidth: '100%' }}
      >
        <WeighingSummary data={summaryData} control={methods.control} />
        {isStatusEditable && (
          <EditStatus
            toggleShow={toggleStatusEditable}
            options={WORK_ORDER_TASK_STATUS}
            handleSubmit={handleSubmitStatus}
            editAll
          />
        )}
        <div
          style={{
            width: 'max-content',
            padding: `${theme.spacing(1.5)} 0`,
          }}
        >
          <Button
            type="button"
            variant="outline"
            style={{
              padding: `${theme.spacing(0.375)} ${theme.spacing(
                0.5,
              )} ${theme.spacing(0.375)} ${theme.spacing()}`,
              width: 'max-content',
              minHeight: 'max-content',
            }}
            onClick={toggleStatusEditable}
          >
            <div>Modifier tous les statuts</div>
          </Button>
        </div>

        <WeighingDataTable
          tasks={tasks}
          control={methods.control}
          isControl={isControl}
          methods={methods}
        />

        <ExtraWeighingTasks
          control={methods.control}
          setValue={methods.setValue}
          isControl={isControl}
        />

        <FooterWrapper>
          <Buttons alignment="center" size="full">
            <Button
              type="button"
              variant="phantom"
              onClick={() => {
                const pathname = isControl
                  ? '/saisies/controle-des-odm'
                  : '/pesees';

                if (location?.search) {
                  history.push(`${pathname}${location?.search}`);
                } else {
                  history.push(pathname);
                }
              }}
            >
              Annuler
            </Button>
            <Button
              type="submit"
              variant="primary"
              isLoading={methods.formState.isSubmitting}
            >
              Enregistrer
            </Button>
          </Buttons>
        </FooterWrapper>
      </form>
    </FormProvider>
  );
};

export default WeighingForm;
