import React from 'react';
import { Stack } from '@tymate/margaret';
import { Container, Title } from '@tymate/elise/ui';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Tabs, Tab, TabNavButton } from '@tymate/elise/ui/tabs';
import { LocationAwareProductionSites } from 'components';

const Wrapper = styled(Stack).attrs({
  size: 'full',
  direction: 'column',
  gutterSize: 1,
})`
  background-color: ${({ theme }) => theme.white};
`;

const SubHeader = () => {
  return (
    <Wrapper>
      <Container variant="main" size="large" style={{ paddingBottom: 0 }}>
        <Stack size="full" alignX="space-between" alignY="baseline">
          <Title>Contrôle de saisies</Title>
          <LocationAwareProductionSites size={285} />
        </Stack>
        <Tabs style={{ margin: 0 }}>
          <Tab>
            <TabNavButton to="/saisies/controle-des-odm" as={NavLink}>
              Contrôle des ODM
            </TabNavButton>
          </Tab>

          {/* <Tab>
            <TabNavButton to="/saisies/gestion-des-alertes" as={NavLink}>
              Gestion des alertes
            </TabNavButton>
          </Tab> */}
        </Tabs>
      </Container>
    </Wrapper>
  );
};

export default SubHeader;
