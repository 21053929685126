import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDeepCompareEffect } from 'react-use';
import { RiErrorWarningLine } from 'react-icons/ri';
import { startOfDay, endOfDay } from 'date-fns';
import { pickBy, isEmpty } from 'lodash';
import { useTheme } from 'styled-components';
import { Stack } from '@tymate/margaret';
import { ActionButton, Container, RawLink } from '@tymate/elise/ui';
import {
  ERRORS,
  formatDate,
  formatSort,
  WEIGHING_WORK_ORDERS_SEARCH_OPTIONS,
} from '@tymate/elise/utils';
import {
  LocationAwareDatePicker,
  LocationAwareSelect,
  LocationAwareSearch,
  ShortPagination,
} from '@tymate/elise/components/New';
import {
  getWeighing,
  getWeighingsWorkOrders,
  sendWorkOrderToPreInvoice,
} from 'api/weighing';
import { useSearchParams } from '@tymate/elise/hooks';
import {
  DataTable,
  Button,
  Checkbox,
  OneLineTruncate,
  Tag,
} from '@tymate/elise/components';
import { ORDER_STATUS, WORK_ORDER_STATUS } from 'utils';
import { SubHeader } from 'components/Entries';
import { MdEdit, MdSend } from 'react-icons/md';
import AccordionWeighing from 'components/Entries/AccordionWeighing';
import { useAppState } from 'hooks';
import { useLocation } from 'react-router-dom';

const STATUS_OPTIONS = [
  { label: 'Tous les statuts', value: undefined },
  ...WORK_ORDER_STATUS,
];

const EntriesList = () => {
  const theme = useTheme();
  const { notify } = useAppState();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { sort, filter = {}, page = 1 } = searchParams;
  const queryClient = useQueryClient();
  const [selectedWeighing, setSelectedWeighing] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);

  const { mutateAsync, isLoading: isLoadingSendWorkPreInvoice } = useMutation(
    values => sendWorkOrderToPreInvoice(values),
    {
      onSuccess: () => {
        setSelectedIds([]);
        queryClient.invalidateQueries(['entriesList']);
        notify(
          selectedIds?.length > 1
            ? 'Les pesées ont bien été envoyées en pré-facture.'
            : 'La pesée a bien été envoyée en pré-facture.',
        );
        queryClient.invalidateQueries('entriesList');
      },
      onError: () => {
        notify(ERRORS.COMMON, {
          type: 'error',
        });
      },
    },
  );

  const { data: weighingData } = useQuery(
    ['weighing', selectedWeighing?.id?.toString()],
    () =>
      getWeighing({
        weighingId: selectedWeighing.id,
      }),
    {
      enabled: Boolean(selectedWeighing?.id),
    },
  );
  const weighing = weighingData?.data;

  const { data: weighingsData, isLoading } = useQuery(
    ['entriesList', page, sort, filter],
    () =>
      getWeighingsWorkOrders({
        params: pickBy({
          'page[size]': 50,
          'page[number]': page || 1,
          sort: formatSort(sort) || '-weighing_alert,date',
          'filter[id]': filter?.id,
          'filter[order_id]': filter?.orderId,
          'filter[axonaut_company_name]': filter?.axonautCompanyName,
          'filter[collection_point]': filter?.collectionPoint,
          'filter[truck_identifier]': filter?.truckIdentifier,
          'filter[production_site_id]': filter?.productionSiteId,
          'filter[status]': 'ongoing_control',
          'filter[weighing_status]': filter?.status,
          'by_period[start_date]': formatDate(filter?.startAt),
          'by_period[end_date]': formatDate(filter?.endAt),
        }),
      }),
    {
      onSuccess: ({ data }) => {
        if (data?.[0]?.weighing?.id) {
          setSelectedWeighing({ id: data?.[0]?.weighing?.id, indexRow: 0 });
        }
      },
    },
  );

  const weighings = weighingsData?.data || [];

  const allIdsAreSelected =
    selectedIds?.length > 0 && selectedIds?.length === weighings?.length;

  const data = weighings.map(
    (
      {
        id,
        orderId,
        collectionPointName,
        date,
        truckIdentifier,
        status,
        weighing,
      },
      index,
    ) => {
      return {
        select: {
          render: () => (
            <Checkbox
              checked={selectedIds.some(selectedId => selectedId === id)}
              onClick={() => handleToggleId(id)}
            />
          ),
        },
        id: { value: id },
        orderId: { value: orderId },
        collectionPointName: {
          value: collectionPointName,
          render: () => <OneLineTruncate text={collectionPointName} />,
        },
        date: {
          value: date,
          render: () => (
            <span>{date ? formatDate(new Date(date), 'dd MMM yyyy') : ''}</span>
          ),
        },
        truckIdentifier: {
          value: truckIdentifier,
          render: () => <OneLineTruncate text={truckIdentifier} />,
        },
        status: {
          value: weighing?.status || status,
          render: () => (
            <div style={{ minWidth: 'max-content' }}>
              <Tag value={weighing?.status || status} options={ORDER_STATUS} />
            </div>
          ),
        },
        weighingAlert: {
          value: weighing?.alert,
          render: () =>
            weighing?.alert ? (
              <RiErrorWarningLine size={26} color={theme.error} />
            ) : null,
        },
        onClick: () => {
          if (selectedWeighing?.id !== weighing?.id) {
            setSelectedWeighing({ id: weighing?.id, indexRow: index });
          } else {
            setSelectedWeighing(null);
          }
        },
        actions: {
          render: () => (
            <ActionButton
              hasNoMargin
              as={RawLink}
              variant="isolated"
              to={`/saisies/controle-des-odm/${weighing?.id}${location?.search}`}
            >
              <MdEdit size={24} />
            </ActionButton>
          ),
        },
      };
    },
  );
  const handleToggleId = id => {
    if (selectedIds.some(selectedId => selectedId === id)) {
      setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleToggleAllIds = () => {
    if (allIdsAreSelected) {
      setSelectedIds([]);
    } else if (selectedIds.length > 0) {
      setSelectedIds([]);
    } else {
      setSelectedIds(weighings.map(({ id }) => id));
    }
  };

  const headings = [
    {
      slug: 'select',
      render: () => (
        <Checkbox
          onClick={handleToggleAllIds}
          color={theme.textLighten}
          checked={allIdsAreSelected}
          indeterminate={selectedIds.length > 0 && !allIdsAreSelected}
          disabled={weighings?.length === 0}
          size={20}
        />
      ),
      cannotBeReordered: true,
    },
    { slug: 'id', label: 'ID' },
    { slug: 'orderId', label: 'ID Commande' },
    { slug: 'collectionPointName', label: 'Point de collecte' },
    { slug: 'date', label: 'Date' },
    { slug: 'truckIdentifier', label: 'Tournée' },
    { slug: 'status', label: 'Statut', cannotBeReordered: true },
    {
      slug: 'weighingAlert',
      label: <RiErrorWarningLine size={26} style={{ transform: 'unset' }} />,
    },
    {
      slug: 'actions',
      label: '',
      cannotBeReordered: true,
    },
  ];

  useDeepCompareEffect(() => {
    let params = {};

    if (!filter?.search) {
      params['filter[search]'] = WEIGHING_WORK_ORDERS_SEARCH_OPTIONS[0].value;
    }

    if (!filter?.startAt) {
      params['filter[startAt]'] = startOfDay(new Date()).toISOString();
    }

    if (!filter?.endAt) {
      params['filter[endAt]'] = startOfDay(new Date()).toISOString();
    }

    if (!isEmpty(params)) {
      setSearchParams({ replace: true, ...params });
    }
  }, [
    { search: filter?.search, startAt: filter?.startAt, endAt: filter?.endAt },
  ]);

  return (
    <>
      <SubHeader />
      <Container variant="main" size="large">
        <DataTable
          headings={headings}
          data={data}
          loading={isLoading}
          shouldScrollHorizontally={false}
          accordion={{
            indexRow: selectedWeighing?.indexRow,
            component: <AccordionWeighing weighing={weighing} />,
          }}
          action={
            <Stack alignY="center" alignX="space-between" size="full">
              <Stack
                direction={{ default: 'column', medium: 'row' }}
                gutterSize={1}
                alignY="center"
                size="full"
              >
                <Stack alignY="center">
                  <LocationAwareSelect
                    variant="input"
                    options={WEIGHING_WORK_ORDERS_SEARCH_OPTIONS}
                    searchParamName="filter[search]"
                    isSelectAndSearch
                  />
                  <LocationAwareSearch
                    searchParamName={searchParams?.filter?.search}
                    omitSearchParams={WEIGHING_WORK_ORDERS_SEARCH_OPTIONS.map(
                      ({ value }) => value,
                    )}
                    isSelectAndSearch
                  />
                </Stack>
                <div>
                  <LocationAwareSelect
                    options={STATUS_OPTIONS}
                    searchParamName="filter[status]"
                    value={filter?.status}
                    size={200}
                  />
                </div>
                <Stack
                  alignY="center"
                  style={{ flexWrap: 'wrap', gap: '0.5rem' }}
                >
                  <p>Du</p>
                  <LocationAwareDatePicker
                    searchParamName="filter[startAt]"
                    maxDate={new Date(searchParams?.filter?.endAt)}
                  />
                  <p>au</p>
                  <LocationAwareDatePicker
                    searchParamName="filter[endAt]"
                    minDate={new Date(searchParams?.filter?.startAt)}
                    maxDate={endOfDay(new Date())}
                  />
                </Stack>
              </Stack>
              <Button
                variant="primary"
                type="button"
                onClick={() => mutateAsync({ workOrderIds: selectedIds })}
                icon={<MdSend size={32} />}
                style={{
                  padding: isLoadingSendWorkPreInvoice
                    ? `${theme.spacing(1)} ${theme.spacing(1.5)}`
                    : `${theme.spacing(0.5)} ${theme.spacing()}`,
                }}
                disabled={selectedIds?.length === 0}
                isLoading={isLoadingSendWorkPreInvoice}
              >
                {`Pré-facture ${
                  selectedIds?.length > 0 ? `(${selectedIds?.length})` : ''
                }`}
              </Button>
            </Stack>
          }
        />
        <ShortPagination meta={weighingsData?.headers} />
      </Container>
    </>
  );
};

export default EntriesList;
