import React from 'react';
import { Stack } from '@tymate/margaret';
import { useFieldArray, useWatch } from 'react-hook-form';
import { MdAddCircleOutline } from 'react-icons/md';
import { Button } from '@tymate/elise/ui';
import ExtraWeighingTask from './ExtraWeighingTask';

const ExtraWeighingTasks = ({ control, setValue, isControl }) => {
  const extraWeighingTasks = useWatch({
    control,
    name: 'extraWeighingTasks',
  });
  const { append, remove, fields, update } = useFieldArray({
    control: control,
    name: 'extraWeighingTasks',
  });

  const handleAddExtraWeighing = () => {
    append({
      totalTare: null,
      measurement: null,
      totalMeasurement: null,
      description: '',
      resourceId: null,
      containerResourceId: null,
    });
  };

  return (
    <>
      <Stack
        direction="column"
        size="full"
        gutterSize={1.5}
        paddingVertical={1.5}
      >
        {(fields || [])?.map((extraWeighingTask, indexRow) => {
          if (extraWeighingTask?._destroy) {
            return undefined;
          }

          return (
            <ExtraWeighingTask
              key={indexRow}
              indexRow={indexRow}
              setValue={setValue}
              isControl={isControl}
              extraWeighingTask={extraWeighingTasks[indexRow]}
              remove={remove}
              update={update}
            />
          );
        })}
      </Stack>

      <Button variant="outline" type="button" onClick={handleAddExtraWeighing}>
        <MdAddCircleOutline />
        Ajouter une prestation non-programmée
      </Button>
    </>
  );
};

export default ExtraWeighingTasks;
