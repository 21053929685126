import React from 'react';
import { Modal } from '@tymate/elise/components';
import TareForm from 'components/Forms/TareForm';

const EditTare = ({
  setValue,
  toggleShow,
  indexRow,
  defaultValue,
  isDowngrading,
  setIsFirstDowngradingLoading,
  setDowngradingTotalTare,
}) => {
  const onSubmit = ({ tare }) => {
    if (isDowngrading) {
      setIsFirstDowngradingLoading(true);
      setDowngradingTotalTare(tare);
    }
    setValue(
      isDowngrading
        ? `weighingTasks[${indexRow}].downgrading.totalTare`
        : `weighingTasks[${indexRow}].totalTare`,
      tare,
    );
    toggleShow();
  };

  return (
    <Modal
      isOpen
      title={
        isDowngrading
          ? 'Modifier la tare totale du déclassement'
          : 'Modifier la tare totale'
      }
      onRequestClose={toggleShow}
    >
      <TareForm
        defaultValue={defaultValue}
        onSubmit={onSubmit}
        onRequestClose={toggleShow}
      />
    </Modal>
  );
};

export default EditTare;
