import React from 'react';
import styled, { useTheme } from 'styled-components';
import { MdSubdirectoryArrowRight } from 'react-icons/md';
import { Stack } from '@tymate/margaret';
import { Status } from 'ui';
import { Cell, TaskRow } from 'ui/entries';
import { getStatusByValue } from 'utils';
import { Text } from 'ui/typography';

const Wrapper = styled(Stack).attrs({
  size: 'full',
  direction: 'column',
})`
  border-top: 1px solid ${({ theme }) => theme.separator};
`;

const DescendantWeighingTask = ({ descendantTask, weighingId }) => {
  const theme = useTheme();
  const title = `${descendantTask?.product?.service?.name} de ${
    descendantTask.product?.resource?.name
  } ${descendantTask?.quantity > 1 ? `x${descendantTask?.quantity}` : ''}`;
  const ref = `${weighingId}-${descendantTask?.id}`;
  const statusInfo = getStatusByValue(descendantTask?.status);

  return (
    <Wrapper>
      <TaskRow>
        <Cell gridArea="work-order">
          <Stack gutterSize={0.25}>
            <MdSubdirectoryArrowRight size={20} />
            <Stack direction="column" size="full" gutterSize={0.25}>
              <strong>{title}</strong>
              <div style={{ color: theme.textLighten }}>{ref}</div>
              <Status
                rounded
                backgroundColor={`${statusInfo?.color}Tag`}
                color={`${statusInfo?.color}Label`}
              >
                {statusInfo?.label}
              </Status>
            </Stack>
          </Stack>
        </Cell>

        <Cell gridArea="reporting">
          <Stack direction="column" gutterSize={1} size="full" alignX="end">
            {!['unsto', 'pres'].includes(
              descendantTask?.product?.resource?.unit,
            ) && (
              <Text>
                {descendantTask?.billingMeasurement}{' '}
                {descendantTask?.billingMeasurement
                  ? descendantTask?.product?.resource?.unit
                  : ''}
              </Text>
            )}
          </Stack>
        </Cell>
      </TaskRow>
    </Wrapper>
  );
};

export default DescendantWeighingTask;
