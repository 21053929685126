import styled, { css } from 'styled-components';
import { Stack } from '@tymate/margaret';

export const WeighingTable = styled(Stack).attrs({
  size: 'full',
  direction: 'column',
})`
  background-color: ${({ theme }) => theme.white};
`;

export const Cell = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(0.5)} 0;

  ${({ gridArea }) =>
    gridArea &&
    css`
      grid-area: ${gridArea};
    `}

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `}
`;

export const HeaderRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1.9fr 1.1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'work-order material expected weight reporting';
  font-weight: bold;
  background-color: ${({ theme }) => theme.grayLighten};
  padding: 0 ${({ theme }) => theme.spacing()};
`;

export const TaskRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 4fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'work-order weighing-line reporting';
  background-color: ${({ theme }) => theme.grayLighter};
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
`;

export const WeighingLineRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1.9fr 1.1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'material expected weight';
`;

export const DowngradingRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 5fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'space downgrading';
  background-color: ${({ theme }) => theme.grayLighter};
  padding: 0 ${({ theme }) => theme.spacing()}
    ${({ theme }) => theme.spacing(0.5)};
`;

export const DowngradingWeighingLineRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1.9fr 1.1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'material expected weight .';
`;

export const ExtraWeighingLineRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1.9fr 1.1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'services material container label weight .';
`;
