import React from 'react';
import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import { Row, Cell } from 'ui';
import WeighingDataTableRow from './DataTableRow';
import { EmptyState } from '@tymate/elise/ui';
import { useQuery } from 'react-query';
import { getResources } from 'api/references';
import Spinner from '@tymate/elise/components/Spinner';

const Table = styled(Stack).attrs({ size: 'full', direction: 'column' })`
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};
`;

const WeighingDataTable = ({ tasks, isControl, methods }) => {
  const columnsFlex = [3, 2, 2, 2, 2];

  // Dans certain cas, le vrac doit être renseigné par défaut parmi les weighingTasks si aucune weighingLine n'est présente, on le récupère donc ici pour avoir son id
  const { data, isLoading } = useQuery(
    ['vrac'],
    () => getResources({ params: { container: true, 'filter[name]': 'vrac' } }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
    },
  );
  const vrac = data?.data?.[0];

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Table>
      <Row titleRow>
        <Cell flex={columnsFlex[0]}>Prestation(s)</Cell>
        <Cell flex={columnsFlex[1]}>Matériel</Cell>
        <Cell flex={columnsFlex[2]} />
        <Cell flex={columnsFlex[3]}>Poids / Unité</Cell>
        <Cell flex={columnsFlex[4]}>Reporting</Cell>
      </Row>
      <>
        {tasks.length === 0 ? (
          <EmptyState>Il n'y a pas de prestation à renseigner</EmptyState>
        ) : (
          <>
            {tasks.map((task, indexRow) => {
              return (
                <WeighingDataTableRow
                  vrac={vrac}
                  initialWeighingLines={task?.weighingLines?.length}
                  indexRow={indexRow}
                  columnsFlex={columnsFlex}
                  defaultTask={task}
                  isControl={isControl}
                  methods={methods}
                />
              );
            })}
          </>
        )}
      </>
    </Table>
  );
};

export default WeighingDataTable;
