import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import WeighingList from './WeighingList';
import NewWeighing from './NewWeighing';
import EditWeighing from './EditWeighing';

const Weighings = () => (
  <Switch>
    <Route path="/pesees/:workOrderId/nouveau" component={NewWeighing} exact />
    <Route path="/pesees/:weighingId/modifier" component={EditWeighing} exact />
    <Route path="/pesees" component={WeighingList} />
    <Redirect to="/pesees" />
  </Switch>
);

export default Weighings;
