import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import EntryList from './EntryList';
import EditWeighing from 'containers/Weighings/EditWeighing';

const Entries = () => (
  <Switch>
    <Route
      path="/saisies/controle-des-odm/:weighingId"
      component={EditWeighing}
      exact
    />
    <Route path="/saisies/controle-des-odm" component={EntryList} />
    {/* <Route path="/saisies/gestion-des-alertes" component={EntryList} /> */}
    <Redirect to="/saisies/controle-des-odm" />
  </Switch>
);

export default Entries;
