import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import RawDatePicker from 'react-date-picker';
import { ButtonReset } from '@tymate/elise/ui';
import { DatePickerStyle } from '@tymate/elise/ui/date-picker';
import { MdDateRange, MdHighlightOff } from 'react-icons/md';
import { useSearchParams } from '@tymate/elise/hooks';
import { get } from 'lodash';

const LocationAwareDatePicker = ({ searchParamName, clearIcon, ...props }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = get(searchParams, searchParamName, undefined);
  const datePickerRef = useRef();

  return (
    <div
      onClick={e => {
        // Prevent to open calendar on date clear.
        if (
          e?.target?.getAttribute('data-action') !== 'removeDate' &&
          !props?.disabled
        ) {
          datePickerRef.current.openCalendar();
        }
      }}
      style={{ minWidth: 'max-content' }}
    >
      <DatePickerStyle />
      <RawDatePicker
        ref={datePickerRef}
        locale="fr"
        value={Boolean(value) ? new Date(value) : undefined}
        onChange={value => {
          if (value instanceof Date) {
            setSearchParams({
              replace: true,
              [searchParamName]: value?.toISOString(),
            });
          }
        }}
        clearIcon={
          clearIcon ? (
            <ButtonReset
              phantom
              style={{ opacity: Boolean(value) ? 1 : 0 }}
              data-action={value ? 'removeDate' : ''}
              onClick={() =>
                setSearchParams({
                  replace: true,
                  omitSearchParams: [searchParamName],
                })
              }
            >
              <MdHighlightOff style={{ pointerEvents: 'none' }} size="18" />
            </ButtonReset>
          ) : null
        }
        calendarIcon={<MdDateRange size={20} />}
        {...props}
      />
    </div>
  );
};

LocationAwareDatePicker.propTypes = {
  searchParamName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  clearIcon: PropTypes.bool,
};

export default LocationAwareDatePicker;
