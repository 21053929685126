import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import { startOfDay, endOfDay } from 'date-fns';
import { isEmpty, pickBy } from 'lodash';
import { useTheme } from 'styled-components';
import { Stack } from '@tymate/margaret';
import { MdSend, MdWarning } from 'react-icons/md';
import { Container } from '@tymate/elise/ui';
import {
  ERRORS,
  formatDate,
  formatSort,
  WEIGHING_WORK_ORDERS_SEARCH_OPTIONS,
} from '@tymate/elise/utils';
import {
  LocationAwareDatePicker,
  LocationAwareSelect,
  LocationAwareSearch,
  ShortPagination,
} from '@tymate/elise/components/New';
import {
  getWeighingsWorkOrders,
  sendWorkOrderToEntryControl,
} from 'api/weighing';
import { useSearchParams } from '@tymate/elise/hooks';
import {
  DataTable,
  Button,
  Checkbox,
  OneLineTruncate,
  Tag,
} from '@tymate/elise/components';
import {
  WORK_ORDER_STATUS,
  STATUS_ACCEPTED_FOR_ENTRY_CONTROL,
  ORDER_STATUS,
} from 'utils';
import SubHeader from 'components/Weighings/SubHeader';
import { useAppState } from 'hooks';

const STATUS_OPTIONS = [
  { label: 'Tous les statuts', value: undefined },
  ...WORK_ORDER_STATUS,
];

const WeighingList = () => {
  const theme = useTheme();
  const location = useLocation();
  const { notify } = useAppState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { sort = '-date', filter = {}, page = 1 } = searchParams;
  const queryClient = useQueryClient();
  const [redirectUrl, setRedirectUrl] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);

  const { mutateAsync, isLoading: isLoadingSendWorkControl } = useMutation(
    values => sendWorkOrderToEntryControl(values),
    {
      onSuccess: () => {
        setSelectedIds([]);
        queryClient.invalidateQueries(['weighingList']);
        queryClient.invalidateQueries(['entriesList']);
        notify(
          selectedIds?.length > 1
            ? 'Les pesées ont bien été envoyées en contrôle de saisies.'
            : 'La pesée a bien été envoyée en contrôle de saisies.',
        );
      },
      onError: () => {
        notify(ERRORS.COMMON, {
          type: 'error',
        });
      },
    },
  );

  const { data: weighingData, isLoading } = useQuery(
    ['weighingList', page, sort, filter],
    () =>
      getWeighingsWorkOrders({
        params: pickBy({
          'page[size]': 50,
          'page[number]': page || 1,
          sort: formatSort(sort),
          'filter[id]': filter?.id,
          'filter[order_id]': filter?.orderId,
          'filter[axonaut_company_name]': filter?.axonautCompanyName,
          'filter[collection_point]': filter?.collectionPoint,
          'filter[truck_identifier]': filter?.truckIdentifier,
          'filter[production_site_id]': filter?.productionSiteId,
          'filter[status]': 'new',
          'filter[weighing_status]': filter?.status,
          'by_period[start_date]': formatDate(filter?.startAt),
          'by_period[end_date]': formatDate(filter?.endAt),
        }),
      }),
    {
      enabled: Boolean(filter?.endAt),
      onSuccess: response => {
        if (response?.data && response?.data?.length === 1) {
          const firstWorkOrder = response?.data[0];
          const pathRedirection = firstWorkOrder?.weighing?.id
            ? `/pesees/${firstWorkOrder?.weighing?.id}/modifier`
            : `/pesees/${firstWorkOrder?.id}/nouveau`;

          setRedirectUrl(pathRedirection);
        }
      },
    },
  );

  const weighings = weighingData?.data || [];

  const weighingsValidForControl = weighings.filter(({ weighing }) =>
    STATUS_ACCEPTED_FOR_ENTRY_CONTROL.includes(weighing?.status),
  );

  const allIdsAreSelected =
    selectedIds?.length > 0 &&
    selectedIds?.length === weighingsValidForControl?.length;

  const data = weighings.map(
    ({
      id,
      orderId,
      axonautCompanyName,
      collectionPointName,
      date,
      truckIdentifier,
      status,
      alert,
      weighing,
    }) => {
      const path = weighing?.id
        ? `/pesees/${weighing?.id}/modifier${location?.search}`
        : `/pesees/${id}/nouveau${location?.search}`;

      return {
        select: {
          render: () => (
            <Checkbox
              checked={selectedIds.some(selectedId => selectedId === id)}
              onClick={() => handleToggleId(id)}
              disabled={
                !STATUS_ACCEPTED_FOR_ENTRY_CONTROL.includes(
                  weighing?.status || status,
                )
              }
            />
          ),
        },
        workOrderId: { value: id },
        orderId: { value: orderId },
        axonautCompanyName: {
          value: axonautCompanyName,
          render: () => <OneLineTruncate text={axonautCompanyName} />,
        },
        collectionPointName: {
          value: collectionPointName,
          render: () => <OneLineTruncate text={collectionPointName} />,
        },
        date: {
          value: date,
          render: () => (
            <span>{date ? formatDate(new Date(date), 'dd MMM yyyy') : ''}</span>
          ),
        },
        truckIdentifier: {
          value: truckIdentifier,
          render: () => <OneLineTruncate text={truckIdentifier} />,
        },
        status: {
          value: weighing?.status || status,
          render: () => (
            <div style={{ minWidth: 'max-content' }}>
              <Tag value={weighing?.status || status} options={ORDER_STATUS} />
            </div>
          ),
        },
        alert: {
          value: alert,
          render: () => (alert ? <MdWarning color={theme.error} /> : null),
        },
        path,
      };
    },
  );

  const handleToggleId = id => {
    if (selectedIds.some(selectedId => selectedId === id)) {
      setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleToggleAllIds = () => {
    if (allIdsAreSelected) {
      setSelectedIds([]);
    } else if (selectedIds.length > 0) {
      setSelectedIds([]);
    } else {
      setSelectedIds(weighingsValidForControl.map(({ id }) => id));
    }
  };

  const headings = [
    {
      slug: 'select',
      render: () => (
        <Checkbox
          onClick={handleToggleAllIds}
          color={theme.textLighten}
          checked={allIdsAreSelected}
          indeterminate={selectedIds.length > 0 && !allIdsAreSelected}
          disabled={weighingsValidForControl?.length === 0}
          size={20}
        />
      ),
      cannotBeReordered: true,
    },
    { slug: 'workOrderId', label: 'ID' },
    { slug: 'orderId', label: 'ID Commande' },
    { slug: 'axonautCompanyName', label: 'Client commande' },
    { slug: 'collectionPointName', label: 'Point de collecte' },
    { slug: 'date', label: 'Date' },
    { slug: 'truckIdentifier', label: 'Tournée' },
    { slug: 'status', label: 'Statut', cannotBeReordered: true },
    { slug: 'alert', label: '', cannotBeReordered: true },
  ];

  useDeepCompareEffect(() => {
    let params = {};

    if (!filter?.search) {
      params['filter[search]'] = WEIGHING_WORK_ORDERS_SEARCH_OPTIONS[0].value;
    }

    if (!filter?.endAt) {
      params['filter[endAt]'] = startOfDay(new Date()).toISOString();
    }

    if (!isEmpty(params)) {
      setSearchParams({ replace: true, ...params });
    }
  }, [
    { search: filter?.search, startAt: filter?.startAt, endAt: filter?.endAt },
  ]);

  return (
    <>
      <SubHeader />
      <Container variant="main" size="large">
        <DataTable
          headings={headings}
          data={data}
          loading={isLoading}
          shouldScrollHorizontally={false}
          action={
            <Stack alignY="center" alignX="space-between" size="full">
              <Stack
                direction={{ default: 'column', medium: 'row' }}
                gutterSize={1}
                alignY="center"
                size="full"
              >
                <Stack alignY="center">
                  <LocationAwareSelect
                    variant="input"
                    options={WEIGHING_WORK_ORDERS_SEARCH_OPTIONS}
                    searchParamName="filter[search]"
                    isSelectAndSearch
                  />
                  <LocationAwareSearch
                    searchParamName={searchParams?.filter?.search}
                    omitSearchParams={WEIGHING_WORK_ORDERS_SEARCH_OPTIONS.map(
                      ({ value }) => value,
                    )}
                    isSelectAndSearch
                    isRedirectScan
                    redirectUrl={redirectUrl}
                  />
                </Stack>
                <div>
                  <LocationAwareSelect
                    options={STATUS_OPTIONS}
                    searchParamName="filter[status]"
                    value={filter?.status}
                    size="200"
                  />
                </div>
                <Stack
                  alignY="center"
                  style={{ flexWrap: 'wrap', gap: '0.5rem' }}
                >
                  <p>Du</p>
                  <LocationAwareDatePicker
                    searchParamName="filter[startAt]"
                    maxDate={new Date(searchParams?.filter?.endAt)}
                  />
                  <p>au</p>
                  <LocationAwareDatePicker
                    searchParamName="filter[endAt]"
                    minDate={new Date(searchParams?.filter?.startAt)}
                    maxDate={endOfDay(new Date())}
                  />
                </Stack>
              </Stack>
              <Button
                variant="primary"
                type="button"
                onClick={() => mutateAsync({ workOrderIds: selectedIds })}
                icon={<MdSend size={32} />}
                style={{
                  padding: isLoadingSendWorkControl
                    ? `${theme.spacing(1)} ${theme.spacing(1.5)}`
                    : `${theme.spacing(0.5)} ${theme.spacing()}`,
                }}
                disabled={selectedIds?.length === 0}
                isLoading={isLoadingSendWorkControl}
              >
                {`Contrôle ${
                  selectedIds?.length > 0 ? `(${selectedIds?.length})` : ''
                }`}
              </Button>
            </Stack>
          }
        />
        <ShortPagination meta={weighingData?.headers} />
      </Container>
    </>
  );
};

export default WeighingList;
