import { post, get } from './';
import { CLIENT_ID } from '../constants';

export const signIn = payload =>
  post(`/oauth/token`, {
    ...payload,
    clientId: CLIENT_ID,
    grantType: 'password',
    scope: 'weighing',
  });

export const refreshToken = payload =>
  post(`/oauth/token`, {
    ...payload,
    clientId: CLIENT_ID,
    grantType: 'refresh_token',
  });

export const getMe = () => get(`/v1/users/me`);
