import { Stack } from '@tymate/margaret';
import styled, { useTheme } from 'styled-components';
import { ActionButton } from '@tymate/elise/ui';
import TextField from '@tymate/elise/components/Fields/TextField';
import TextareaField from '@tymate/elise/components/Fields/TextareaField';
import SelectSearchQueryField from 'components/Fields/SelectSearchQueryField';
import { getResources } from 'api/references';
import { MdEdit } from 'react-icons/md';
import { useDeepCompareEffect } from 'react-use';
import React, { useReducer, useState } from 'react';
import { Cell } from 'ui';
import { useFormState, useWatch } from 'react-hook-form';
import { calculateTotalWeight, getVariantFromAlertAndDirty } from 'utils';
import EditTare from 'components/Weighings/EditTare';

const NetTotalWithoutTare = ({ productResourceUnit, tare, downgrading }) => {
  const totalWeight = calculateTotalWeight(
    downgrading,
    productResourceUnit,
    tare,
  );

  return (
    <>
      <div />
      <Stack
        alignX="right"
        alignY="center"
        marginRight={-0.125}
        marginLeft={-3}
      >
        <strong>
          {productResourceUnit === 'un'
            ? 'Total :'
            : 'Total net tare déduite :'}
        </strong>
      </Stack>
      <Stack
        size="full"
        alignX="right"
        alignY="center"
        paddingHorizontal={0.5}
        style={{
          fontSize: '18px',
        }}
      >
        <strong>{totalWeight}</strong>
      </Stack>
      <Stack
        alignY="center"
        alignX="right"
        style={{
          fontSize: '18px',
        }}
      >
        <strong>{productResourceUnit}</strong>
      </Stack>
      <div />
    </>
  );
};

const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 5fr 5fr 3fr 1fr 24px;
  gap: 0px 12px;
  min-width: 100%;
`;

const DowngradingFormInControl = ({
  methods,
  task,
  formPath,
  columnsFlex,
  defaultTask,
  indexRow,
}) => {
  const theme = useTheme();
  const [showDowngradingTareModal, toggleShowDowngradingTareModal] = useReducer(
    state => !state,
    false,
  );
  const [isFirstDowngradingLoading, setIsFirstDowngradingLoading] = useState(
    true,
  );
  const [downgradingTotalTare, setDowngradingTotalTare] = useState(
    task?.downgrading?.totalTare,
  );
  const watchContainers = useWatch(
    task?.downgrading?.weighingLines?.map(
      (_, index) => `${formPath}.weighingLines[${index}].containerResourceId`,
    ),
  );
  const watchMeasurements = useWatch(
    task?.downgrading?.weighingLines?.map(
      (_, index) => `${formPath}.weighingLines[${index}].measurement`,
    ),
  );

  useDeepCompareEffect(() => {
    if (task?.downgrading?.totalTare && isFirstDowngradingLoading) {
      setIsFirstDowngradingLoading(false);
      return;
    }

    const downgradingWeighingLines = task?.downgrading?.weighingLines;

    if (downgradingWeighingLines) {
      let computedTare = 0;
      downgradingWeighingLines.forEach(weighingLine => {
        computedTare += weighingLine?.containerResourceId?.tare || 0;
      });
      if (isNaN(computedTare)) {
        computedTare = 0;
      }
      methods.setValue(`${formPath}.totalTare`, computedTare);
      setDowngradingTotalTare(computedTare);
    }
  }, [
    {
      taskTotalTare: task?.downgrading?.totalTare,
      watchContainers,
      watchMeasurements,
    },
  ]);

  const { dirtyFields } = useFormState({ control: methods?.control });

  return (
    <>
      {showDowngradingTareModal && (
        <EditTare
          toggleShow={toggleShowDowngradingTareModal}
          indexRow={indexRow}
          setValue={methods.setValue}
          defaultValue={task?.downgrading?.totalTare}
          isDowngrading
          setIsFirstDowngradingLoading={setIsFirstDowngradingLoading}
          setDowngradingTotalTare={setDowngradingTotalTare}
        />
      )}
      <Stack direction="column" gutterSize={1} size="full">
        <Stack>
          <Cell
            flex={columnsFlex?.[1] + columnsFlex?.[2] + columnsFlex?.[3]}
            style={{ paddingLeft: 0, paddingTop: theme.spacing(1) }}
          >
            <Stack direction="column" gutterSize={1} size="full">
              {task?.downgrading?.weighingLines?.map((item, index) => {
                const defaultWeighingLine =
                  defaultTask?.downgrading?.weighingLines?.[index];
                const dirtyDowngradingFields =
                  dirtyFields?.weighingTasks?.[indexRow]?.downgrading
                    ?.weighingLines?.[index];
                return !item._destroy ? (
                  <ItemGrid key={item.id}>
                    <Stack size="full">
                      <SelectSearchQueryField
                        name={`${formPath}.weighingLines[${index}].containerResourceId`}
                        queryKey={['resources']}
                        queryFn={getResources}
                        params={{ container: true }}
                        placeholder="Contenant..."
                        labelKey="name"
                        labelValue="id"
                        initialValueLabel={item?.containerResourceId?.label}
                        storeEntireOption
                        optionalKeys={['tare']}
                      />
                    </Stack>
                    <Stack
                      gutterSize={0.5}
                      size="full"
                      alignY="center"
                      alignX="flex-end"
                    >
                      {task?.identifiedContainersRequired ? (
                        <TextField
                          name={`${formPath}.weighingLines[${index}].identifier`}
                          placeholder="Identification"
                          textAlign="right"
                          variant={getVariantFromAlertAndDirty(
                            defaultWeighingLine?.missingIdentifierAlert,
                            dirtyDowngradingFields?.identifier,
                            true,
                          )}
                        />
                      ) : (
                        <TextField
                          textAlign="right"
                          name={`${formPath}.weighingLines[${index}].containerCount`}
                          placeholder="Quantité"
                          variant={getVariantFromAlertAndDirty(
                            defaultWeighingLine?.containerCountAlert,
                            dirtyDowngradingFields?.containerCount,
                            true,
                          )}
                        />
                      )}
                    </Stack>
                    <Stack
                      size="full"
                      alignY="center"
                      style={{ margin: '0px 8px' }}
                    >
                      <TextField
                        name={`${formPath}.weighingLines[${index}].measurement`}
                        textAlign="right"
                        variant={getVariantFromAlertAndDirty(
                          defaultWeighingLine?.missingMeasurementAlert,
                          dirtyDowngradingFields?.measurement,
                          true,
                        )}
                      />
                    </Stack>
                    <Stack alignX="right" size="full" alignY="center">
                      {task?.productResourceUnit}
                    </Stack>
                  </ItemGrid>
                ) : null;
              })}

              <ItemGrid>
                <div />
                {task?.productResourceUnit !== 'un' &&
                  task?.downgrading?.weighingLines?.length !== 0 && (
                    <>
                      <Stack
                        alignY="center"
                        alignX="right"
                        marginRight={-0.125}
                        style={{
                          fontSize: '14px',
                        }}
                      >
                        Tare totale :
                      </Stack>
                      <Stack style={{ margin: '0 8px' }} size="full">
                        <TextField
                          name={`weighingTasks[${indexRow}].downgrading.totalTare`}
                          disabled
                          textAlign="right"
                        />
                      </Stack>
                      <Stack size="full" alignY="center" alignX="right">
                        {task?.productResourceUnit}
                      </Stack>
                      <Stack size="full" alignY="center">
                        <ActionButton
                          type="button"
                          onClick={toggleShowDowngradingTareModal}
                        >
                          <MdEdit color={theme.grayLight} />
                        </ActionButton>
                      </Stack>
                    </>
                  )}
              </ItemGrid>
              <ItemGrid>
                <NetTotalWithoutTare
                  downgrading={task?.downgrading}
                  tare={downgradingTotalTare}
                  productResourceUnit={task?.productResourceUnit}
                  control={methods?.control}
                />
              </ItemGrid>
            </Stack>
          </Cell>
          <Cell flex={columnsFlex?.[4]} />
        </Stack>

        <TextareaField
          label="Description"
          name={`${formPath}.description`}
          minRows={3}
          control={methods?.control}
        />
      </Stack>
    </>
  );
};

export default DowngradingFormInControl;
