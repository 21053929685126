import React, { useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { flatMap, merge, pickBy } from 'lodash';
import InfiniteScrollSensor from '@tymate/elise/components/InfiniteScrollSensor';
import { FormError, FormField } from '@tymate/elise/ui/forms';
import { Select } from '@tymate/elise/components';

const SelectField = ({ name, onChange, setValueLabel, ...props }) => {
  const {
    formState: { errors },
    register,
    setValue,
  } = useFormContext();
  const error = errors[name];

  return (
    <FormField>
      <Select
        {...props}
        onChange={option => {
          setValueLabel(option?.label);

          if (props?.storeEntireOption) {
            setValue(name, option);
          } else {
            setValue(name, option?.value);
          }

          onChange(option);
        }}
        register={register(name)}
        variant="input"
        size="auto"
        showOptionalLabel={props.showOptionalLabel}
        hasCompactDropList={props.hasCompactDropList}
        hasError={error}
      />
      {error && error.message && (
        <FormError role="alert">{error.message}</FormError>
      )}
    </FormField>
  );
};

const SelectSearchQueryField = ({
  queryFn,
  queryKey,
  valueKey,
  labelKey,
  searchKey,
  optionalKeys = [],
  params,
  onChange = () => {},
  ...props
}) => {
  const [search, setSearch] = useState('');
  const [value, setValueLabel] = useState(props.initialValueLabel);

  const query = async ({ pageParam }) => {
    const currentPage = pageParam?.currentPage || 0;
    const totalPages = pageParam?.totalPages || 1;

    if (Boolean(currentPage) && currentPage >= totalPages) {
      return;
    }

    const { data, headers } = await queryFn({
      params: pickBy(
        merge(params, {
          'page[number]': (currentPage || 0) + 1,
          [searchKey]: search,
        }),
      ),
    });

    const nextPageParam = {
      currentPage: Number(headers.paginationCurrentPage || 1),
      totalPages: Number(headers.paginationTotalPages || 1),
    };

    return { data, pageParam: nextPageParam };
  };

  const { data, fetchNextPage } = useInfiniteQuery(
    [...queryKey, search],
    query,
    {
      getNextPageParam: ({ pageParam } = {}) => pageParam,
      keepPreviousData: true,
    },
  );

  const options = flatMap(data?.pages, ({ data } = {}) =>
    (data || []).filter(Boolean).map(element => {
      let optionalKeyValues = {};

      if (optionalKeys?.length > 0) {
        optionalKeys.forEach(key => (optionalKeyValues[key] = element?.[key]));
      }

      return {
        value: element?.[valueKey],
        label: element?.[labelKey],
        ...optionalKeyValues,
      };
    }),
  );

  return (
    <SelectField
      {...props}
      options={options}
      isSearchable
      onUpdateSearch={setSearch}
      initialValueLabel={value}
      setValueLabel={setValueLabel}
      onChange={onChange}
    >
      <InfiniteScrollSensor onLoadMore={fetchNextPage} />
      <div style={{ paddingBottom: 1 }} />
    </SelectField>
  );
};

SelectSearchQueryField.propTypes = {
  name: PropTypes.string.isRequired,
  query: PropTypes.func.isRequired,
  queryKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  labelKey: PropTypes.array.isRequired,
  searchKey: PropTypes.string,
};

SelectSearchQueryField.defaultProps = {
  valueKey: 'id',
  labelKey: 'name',
  searchKey: 'filter[name]',
};
export default SelectSearchQueryField;
