import React from 'react';
import { Stack } from '@tymate/margaret';
import styled from 'styled-components';
import { ActionButton, theme } from '@tymate/elise/ui';
import { MdDelete } from 'react-icons/md';

import DowngradingFormInControl from 'components/Forms/DowngradingFormInControl';

const DowngradingWrapper = styled(Stack).attrs({
  size: 'full',
  direction: 'column',
  gutterSize: '2',
  padding: 1,
})`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.errorLight};
  border: 1px solid ${({ theme }) => theme.error};
`;

const DowngradingDisplayInControl = ({
  toggleShowDeleteDowngradeModal,
  ...props
}) => {
  return (
    <DowngradingWrapper>
      <Stack alignX="space-between" size="full">
        <strong>Déclassement en DIB</strong>
        <Stack>
          <ActionButton type="button" onClick={toggleShowDeleteDowngradeModal}>
            <MdDelete color={theme.gray} />
          </ActionButton>
        </Stack>
      </Stack>
      <DowngradingFormInControl {...props} />
    </DowngradingWrapper>
  );
};

export default DowngradingDisplayInControl;
