import { omit, sum } from 'lodash';

const formatOrderLineResources = orderLine =>
  orderLine.orderLineResources
    .map((orderLineResource, index) => {
      if (
        orderLine.identifiedContainersRequired &&
        orderLineResource.estimatedContainerCount > 1
      ) {
        return Array(orderLineResource.estimatedContainerCount).fill({
          orderLineResourceId: orderLineResource?.id,
          containerResourceId: {
            value: orderLineResource?.containerResource?.id,
            label: orderLineResource?.containerResource?.name,
            tare: orderLineResource?.containerResource?.tare,
          },
          container: orderLineResource?.containerResource?.name,
          containerCount: orderLineResource?.estimatedContainerCount,
          identifier: '',
          measurement: null,
        });
      }

      return {
        orderLineResourceId: orderLineResource?.id,
        containerResourceId: {
          value: orderLineResource?.containerResource?.id,
          label: orderLineResource?.containerResource?.name,
          tare: orderLineResource?.containerResource?.tare,
        },
        container:
          orderLineResource?.containerResource?.name +
          (orderLineResource?.estimatedContainerCount > 1
            ? ` x${orderLineResource.estimatedContainerCount}`
            : ''),
        containerCount: orderLineResource?.estimatedContainerCount,
        identifier: '',
        measurement:
          index === 0 && orderLine?.quantity !== 1 ? orderLine?.quantity : null,
      };
    })
    .flat();

const formatDescendantWorkOrderTasks = (descendantTasks, productUnit) => {
  return descendantTasks.map(({ id, product, quantity }) => ({
    workOrderTaskId: id,
    title: `${product?.service?.name} de ${product?.resource?.name} ${
      quantity > 1 ? `x${quantity}` : ''
    }`,
    productResourceUnit: product?.resource?.unit,
    billingMeasurement: null,
    billingMethod: product?.billingMethod,
    billingUnitParent: productUnit,
    billingUnit: product?.unit,
    status: ORDER_STATUS[0].value,
  }));
};

export const formatWorkOrderTasks = (workOrderId, workOrderTasks) => {
  return workOrderTasks.map(
    ({ id, quantity, orderLine, product, descendantTasks, totalTare }) => {
      const orderLineResources = formatOrderLineResources(orderLine) || [];
      const reportWeighings =
        orderLine?.reportingUnits.map(reportingUnit => ({
          measurement: null,
          unit: reportingUnit,
        })) || [];
      const descendantTasksList =
        formatDescendantWorkOrderTasks(descendantTasks, product?.unit) || [];

      return {
        workOrderTaskId: id,
        ref: `${workOrderId}-${id}`,
        title: `${product?.service?.name} de ${product?.resource?.name} ${
          quantity > 1 ? `x${quantity}` : ''
        }`,
        weighingLines: orderLineResources,
        billingMeasurement: null,
        billingUnit: product?.unit,
        billingMethod: product?.billingMethod,
        reportWeighings,
        productResourceUnit: product?.resource?.unit,
        totalTare: product?.resource?.unit === 'un' ? 0 : totalTare,
        totalMeasurement: null,
        status: ORDER_STATUS[0].value,
        identifiedContainersRequired: orderLine?.identifiedContainersRequired,
        descendantWeighingTasks: descendantTasksList,
      };
    },
  );
};

export const formatWeighingTasks = (weighingId, workOrderId, weighingTasks) => {
  return weighingTasks.map(weighingTask => {
    return {
      id: weighingTask?.id,
      ref: `${weighingId}-${weighingTask?.id}`,
      title: `${weighingTask?.product?.service?.name} de ${
        weighingTask.product?.resource?.name
      } ${weighingTask?.quantity > 1 ? `x${weighingTask?.quantity}` : ''}`,
      weighingLines: weighingTask.weighingLines.map(weighingLine => ({
        ...omit(weighingLine, [
          'createdAt',
          'updatedAt',
          'orderLineResource',
          'containerResource',
        ]),
        orderLineResourceId: weighingLine?.orderLineResource?.id,
        containerResourceId: {
          value: weighingLine?.containerResource?.id,
          label: weighingLine?.containerResource?.name,
          tare: weighingLine?.containerResource?.tare,
        },
        container:
          weighingLine?.containerResource?.name +
          (weighingLine?.orderLineResource?.estimatedContainerCount > 1
            ? ` x${weighingLine.orderLineResource.estimatedContainerCount}`
            : ''),
      })),
      billingMeasurement: weighingTask?.billingMeasurement,
      billingUnit: weighingTask?.product?.unit,
      billingMethod: weighingTask?.product?.billingMethod,
      reportWeighings: weighingTask?.reportWeighings?.map(reportWeighing => {
        return {
          id: reportWeighing?.id,
          measurement: reportWeighing?.measurement,
          unit: reportWeighing?.unit,
        };
      }),
      productResourceUnit: weighingTask?.product?.resource?.unit,
      totalTare: weighingTask?.totalTare,
      totalMeasurement: weighingTask?.totalMeasurement,
      totalMeasurementAlert: weighingTask?.totalMeasurementAlert,
      status: weighingTask?.status,
      identifiedContainersRequired: weighingTask?.identifiedContainersRequired,
      descendantWeighingTasks: weighingTask?.descendantWeighingTasks?.map(
        descendantWeighingTask => ({
          id: descendantWeighingTask?.id,
          title: `${descendantWeighingTask?.product?.service?.name} de ${
            descendantWeighingTask?.product?.resource?.name
          } ${
            descendantWeighingTask?.quantity > 1
              ? `x${descendantWeighingTask?.quantity}`
              : ''
          }`,
          billingMethod: descendantWeighingTask?.product?.billingMethod,
          status: descendantWeighingTask?.status,
          totalTare: descendantWeighingTask?.totalTare,
          totalMeasurement: descendantWeighingTask?.totalMeasurement,
          billingMeasurement: descendantWeighingTask?.billingMeasurement,
          billingUnit: descendantWeighingTask?.product?.unit,
          billingUnitParent: weighingTask?.product?.unit,
        }),
      ),
      downgrading: weighingTask?.downgrading
        ? {
            ...omit(weighingTask?.downgrading, ['createdAt', 'updatedAt']),
            weighingLines: weighingTask?.downgrading?.weighingLines?.map(
              weighingLine => ({
                ...omit(weighingLine, [
                  'createdAt',
                  'updatedAt',
                  'orderLineResource',
                  'containerResource',
                ]),
                orderLineResourceId: weighingLine?.orderLineResource?.id,
                containerResourceId: {
                  value: weighingLine?.containerResource?.id,
                  label: weighingLine?.containerResource?.name,
                  tare: weighingLine?.containerResource?.tare,
                },
              }),
            ),
          }
        : null,
    };
  });
};

export const formatExtraWeighingTasks = extraWeighingTasks => {
  return extraWeighingTasks.map(extraWeighingTask => {
    return {
      id: extraWeighingTask?.id,
      totalTare: extraWeighingTask?.totalTare,
      measurement:
        Number(extraWeighingTask?.totalMeasurement || 0) +
        Number(extraWeighingTask?.totalTare || 0),
      totalMeasurement: extraWeighingTask?.totalMeasurement,
      totalMeasurementAlert: extraWeighingTask?.totalMeasurementAlert,
      description: extraWeighingTask?.description,
      serviceId: {
        label: extraWeighingTask?.service?.name,
        value: extraWeighingTask?.service?.id,
      },
      resourceId: {
        label: extraWeighingTask?.resource?.name,
        value: extraWeighingTask?.resource?.id,
        unit: extraWeighingTask?.resource?.unit,
      },
      containerResourceId: {
        label: extraWeighingTask?.containerResource?.name,
        value: extraWeighingTask?.containerResource?.id,
      },
    };
  });
};

export const ORDER_STATUS = [
  { color: 'teal', label: 'À renseigner', value: 'new' },
  { color: 'green', label: 'Réalisé', value: 'realized' },
  {
    color: 'orange',
    label: 'Réalisé partiellement',
    value: 'partially_realized',
  },
  {
    color: 'green',
    label: 'Réalisé sans quantité',
    value: 'realized_without_quantity',
  },
  { color: 'red', label: 'Non réalisé', value: 'unrealized' },
  {
    color: 'red',
    label: 'Entreprise fermée',
    value: 'closed_company',
  },
];

export const getStatusByValue = valueCompared =>
  ORDER_STATUS.find(({ value }) => value === valueCompared);

export const WORK_ORDER_STATUS = [
  ORDER_STATUS[0], // new
  ORDER_STATUS[1], // realized
  ORDER_STATUS[2], // partially_realized
  ORDER_STATUS[4], // unrealized
];

export const WORK_ORDER_TASK_STATUS = [
  ORDER_STATUS[0], // new
  ORDER_STATUS[4], // unrealized
  ORDER_STATUS[3], // realized_without_quantity
  ORDER_STATUS[5], // closed_company
];

const STATUS_ACCEPT_AS_REALIZED = [
  ORDER_STATUS[1].value, // realized
  ORDER_STATUS[3].value, // realized_without_quantity
  ORDER_STATUS[5].value, // closed_company
];

export const STATUS_ACCEPTED_FOR_ENTRY_CONTROL = [
  ORDER_STATUS[1].value, // realized
  ORDER_STATUS[2].value, // partially_realized
  ORDER_STATUS[4].value, // unrealized
];

export const STATUS_AUTOMATIC = [
  ORDER_STATUS[1].value, // realized
  ORDER_STATUS[2].value, // partially_realized
];

export const getWorkOrderStatus = tasks => {
  const allStatus = [
    ...tasks
      .map(({ descendantWeighingTasks, status }) => [
        status,
        ...descendantWeighingTasks?.map(({ status }) => status),
      ])
      .flat(),
  ];

  if (allStatus.every(value => value === ORDER_STATUS[4].value)) {
    return WORK_ORDER_STATUS[3];
  } else if (allStatus.some(value => value === ORDER_STATUS[4].value)) {
    return WORK_ORDER_STATUS[2];
  } else if (
    allStatus.every(value => STATUS_ACCEPT_AS_REALIZED.includes(value))
  ) {
    return WORK_ORDER_STATUS[1];
  }
  return WORK_ORDER_STATUS[0];
};

export const calculateWeighingLineEstimatedTare = ({
  containerResourceId,
  containerCount,
  identifiedContainersRequired,
}) => {
  return (
    Number(containerResourceId?.tare || 0) *
    Number(identifiedContainersRequired ? 1 : containerCount || 0)
  );
};

export const getBillingMeasurementByBillingUnit = (
  weighingTask,
  totalMeasurement,
) => {
  if (weighingTask?.billingUnit?.value === 'unsto') {
    return totalMeasurement;
  } else if (
    weighingTask?.billingUnit?.value === 'pres' ||
    weighingTask?.billingMethod === 'fixed'
  ) {
    return 1;
  } else {
    return weighingTask?.billingMeasurement;
  }
};

export const calculateTotalMeasurement = weighingTask => {
  if (weighingTask?.weighingLines?.length > 0) {
    const measurementsSummation = sum(
      weighingTask.weighingLines.map(({ measurement }) => Number(measurement)),
    );
    const totalTare =
      weighingTask?.productResourceUnit === 'un' ? 0 : weighingTask?.totalTare;

    return measurementsSummation - totalTare;
  } else {
    return weighingTask?.billingMeasurement;
  }
};

export const calculateTotalWeight = (
  downgrading,
  productResourceUnit,
  totalTare,
) => {
  const weighingLinesSum = sum(
    downgrading?.weighingLines?.map(
      ({ measurement }) => Number(measurement) || 0,
    ),
  );

  if (productResourceUnit === 'un') {
    return weighingLinesSum;
  }
  return weighingLinesSum - Number(totalTare || 0);
};

export const getVariantFromAlertAndDirty = (isAlert, isDirty, isControl) => {
  if (isControl) {
    if (isAlert && isDirty) {
      return 'warningFromError';
    } else if (isAlert && !isDirty) {
      return 'error';
    } else if (!isAlert && isDirty) {
      return 'warning';
    } else {
      return '';
    }
  } else {
    return undefined;
  }
};
