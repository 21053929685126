import React from 'react';
import { Stack } from '@tymate/margaret';
import { Button, Modal } from '@tymate/elise/components';
import styled from 'styled-components';
import { Buttons } from '@tymate/elise/ui';

const TextSecondary = styled.p`
  color: ${({ theme }) => theme.textLighten};
`;

const DeleteDowngrade = ({ setValue, toggleShow, indexRow, id, getValues }) => {
  const pathValue = `weighingTasks.${indexRow}.downgrading`;
  const weighingLines = getValues(
    `weighingTasks.${indexRow}.downgrading.weighingLines`,
  );
  const handleSubmit = () => {
    if (id) {
      setValue(pathValue, {
        id: id,
        _destroy: true,
        weighingLines: weighingLines.map(weighingLine => ({
          id: weighingLine?.id,
          _destroy: true,
        })),
      });
    } else {
      setValue(pathValue, null);
    }
    toggleShow();
  };

  const handleAbort = () => {
    toggleShow();
  };

  return (
    <Modal
      isOpen
      title="Supprimer le déclassement ?"
      onRequestClose={handleAbort}
    >
      <Stack direction="column" alignY="flex-start" gutterSize={2} size="full">
        <TextSecondary style={{ textAlign: 'center' }}>
          Attention! Si vous sauvegardez ce formulaire, les informations saisies
          seront définitivement perdues
        </TextSecondary>

        <Buttons style={{ marginTop: '1.5rem' }} alignment="right" size="full">
          <Button type="button" variant="outline" onClick={handleAbort}>
            Annuler
          </Button>
          <Button type="button" variant="primary" onClick={handleSubmit}>
            Oui, supprimer à l'enregistrement
          </Button>
        </Buttons>
      </Stack>
    </Modal>
  );
};

export default DeleteDowngrade;
