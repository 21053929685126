import React, { Fragment } from 'react';
import { WeighingTable, HeaderRow, Cell } from 'ui/entries';
import { EmptyState } from '@tymate/elise/ui';
import WeighingTaskRow from './WeighingTaskRow';
import ExtraWeighingTask from './ExtraWeighingTask';
import { Text } from 'ui/typography';
import DescendantWeighingTask from './DescendantWeighingTask';

const AccordionWeighing = ({ weighing }) => {
  return (
    <WeighingTable>
      <HeaderRow>
        <Cell gridArea="work-order">Prestation(s)</Cell>
        <Cell gridArea="material">Matériel</Cell>
        <Cell gridArea="expected">
          <Text textAlign="end">Réél / Prévu</Text>
        </Cell>
        <Cell gridArea="weight">
          <Text textAlign="end">Poids / Unité</Text>
        </Cell>
        <Cell gridArea="reporting">
          <Text textAlign="end">Reporting</Text>
        </Cell>
      </HeaderRow>

      {weighing?.weighingTasks?.length === 0 &&
      weighing?.extraWeighingTasks?.length === 0 ? (
        <EmptyState>Il n'y a pas de prestation à contrôler</EmptyState>
      ) : (
        <>
          {weighing?.weighingTasks?.map(task => {
            return (
              <Fragment key={task?.id}>
                <WeighingTaskRow task={task} weighingId={weighing?.id} />
                {task?.descendantWeighingTasks?.map(descendantTask => (
                  <DescendantWeighingTask
                    key={descendantTask?.id}
                    weighingId={weighing?.id}
                    descendantTask={descendantTask}
                  />
                ))}
              </Fragment>
            );
          })}
          {weighing?.extraWeighingTasks?.map(task => {
            return <ExtraWeighingTask key={task?.id} task={task} />;
          })}
        </>
      )}
    </WeighingTable>
  );
};

export default AccordionWeighing;
