import React, { useState } from 'react';
import { AuthContext } from 'contexts';
import { getMe, refreshToken as refreshTokenFn } from 'api/auth';
import { useLocalStorage, useDeepCompareEffect } from 'react-use';

const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useLocalStorage('accessToken', null, {
    raw: true,
  });
  const [refreshToken, setRefreshToken] = useLocalStorage(
    'refreshToken',
    null,
    { raw: true },
  );
  const [lastRefreshedAt, setRefreshedAt] = useState();
  const [user, setUser] = useState({});

  const handleUpdate = ({ accessToken, refreshToken }) => {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    setRefreshedAt(new Date());
  };

  const handleReset = () => {
    setUser({});
    setAccessToken('');
    setRefreshToken('');
  };

  const handleRefreshToken = async () => {
    const { data } = await refreshTokenFn({ refreshToken });
    handleUpdate(data);
  };

  const handleGetUser = async () => {
    try {
      const { data } = await getMe();
      setUser(data);
    } catch (err) {}
  };

  useDeepCompareEffect(() => {
    if (Boolean(accessToken)) {
      handleGetUser();
    } else {
      handleReset();
    }
  }, [{ accessToken }]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        refreshToken,
        user,
        setUser,
        handleGetUser,
        lastRefreshedAt,
        onReset: handleReset,
        onUpdate: handleUpdate,
        onRefreshToken: handleRefreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
