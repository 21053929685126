import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@tymate/elise/components';
import { useSearchParams } from '../../hooks';
import { get } from 'lodash';

const LocationAwareSelect = ({
  placeholder,
  options,
  searchParamName,
  defaultValue = '',
  size = 'auto',
  ...props
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = get(searchParams, searchParamName, defaultValue);

  return (
    <Select
      placeholder={placeholder}
      onChange={newValue => {
        setSearchParams({
          replace: true,
          [searchParamName]: newValue?.value,
        });
      }}
      value={value}
      variant="input"
      hasFixedWidth
      size={size}
      options={options}
      {...props}
    />
  );
};

LocationAwareSelect.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  searchParamName: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default LocationAwareSelect;
