import styled, { css } from 'styled-components';

export const Text = styled.div.attrs(({ as }) => ({
  as: as,
}))`
  ${({ color, theme }) =>
    Boolean(color) &&
    css`
      color: ${theme?.colors?.[color] || theme?.[color]};
    `}

  ${({ fontSize }) =>
    Boolean(fontSize) &&
    css`
      font-size: ${fontSize}px;
    `}

  ${({ fontWeight }) =>
    Boolean(fontWeight) &&
    css`
      font-weight: ${fontWeight};
    `}

  ${({ textAlign }) =>
    Boolean(textAlign) &&
    css`
      text-align: ${textAlign};
    `}
  
  ${({ underLine }) =>
    Boolean(underLine) &&
    css`
      text-decoration: underline;
    `}

  ${({ lineThrough }) =>
    Boolean(lineThrough) &&
    css`
      text-decoration: line-through;
    `}

  ${({ italic }) =>
    Boolean(italic) &&
    css`
      font-style: italic;
    `}
  
  ${({ noWrap }) =>
    Boolean(noWrap) &&
    css`
      white-space: nowrap;
    `}

  ${({ noMargin }) =>
    Boolean(noMargin) &&
    css`
      margin: 0;
    `}
`;
