import React from 'react';
import { Buttons } from '@tymate/elise/ui';
import { Button, Modal } from '@tymate/elise/components';

const DeleteExtraWeighingTaskModal = ({ handleDelete, open, setOpen }) => {
  return (
    <Modal
      isOpen={open}
      title="Suppression de prestation non programmée"
      onRequestClose={() => setOpen(false)}
      variant="small"
    >
      Vous êtes sur le point de supprimer la prestation non programmée
      sélectionnée, êtes-vous sûr de vouloir continuer ?
      <Buttons alignment="right" hasMarginTop>
        <Button variant="outline" onClick={() => setOpen(false)}>
          Non, annuler
        </Button>
        <Button variant="primary" onClick={handleDelete}>
          Oui, supprimer
        </Button>
      </Buttons>
    </Modal>
  );
};

export default DeleteExtraWeighingTaskModal;
