import { post, get, put } from '.';

export const createWeighing = payload => post('/v1/weighings', payload);

export const getWeighings = ({ params }) => get('/v1/weighings', { params });

export const getWeighing = ({ weighingId }) =>
  get(`/v1/weighings/${weighingId}`);

export const updateWeighing = ({ weighingId, payload }) =>
  put(`/v1/weighings/${weighingId}`, payload);

export const getWorkOrder = ({ workOrderId }) =>
  get(`/v1/work_orders/${workOrderId}`);

export const getWeighingsWorkOrders = ({ params }) =>
  get(`/v1/weighings/work_orders`, { params });

export const getWeighingsWorkOrder = ({ workOrderId }) =>
  get(`/v1/weighings/work_orders/${workOrderId}`, { workOrderId });

export const sendWorkOrderToEntryControl = payload =>
  post(`/v1/weighings/work_orders/start_control`, payload);

export const sendWorkOrderToPreInvoice = payload =>
  post(`/v1/weighings/work_orders/end_control`, payload);
