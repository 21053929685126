import styled, { css } from 'styled-components';
import { Stack } from '@tymate/margaret';
import { Card as RawCard } from '@tymate/elise/ui/card';

export const Status = styled.div`
  min-width: max-content;
  border-radius: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.primary};
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing()};

  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 16px;
      padding: ${({ theme }) => theme.spacing(0.375)}
        ${({ theme }) => theme.spacing(0.75)};
    `}

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${({ theme }) => theme[backgroundColor]};
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${({ theme }) => theme[color]};
    `}
`;

export const Row = styled(Stack).attrs({
  size: 'full',
})`
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(2)}
    ${({ theme }) => theme.spacing(0.5)};

  ${({ titleRow }) =>
    titleRow &&
    css`
      font-weight: bold;
      margin: 0;
      padding-bottom: ${({ theme }) => theme.spacing(0)};
    `};
`;

export const Cell = styled(Stack)`
  flex: 1;
  text-align: center;
  padding: ${({ theme }) => theme.spacing(0.5)};

  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}
`;

export const Card = styled(RawCard)`
  width: 100%;
`;
