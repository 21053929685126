import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Stack } from '@tymate/margaret';
import { Status } from 'ui';
import { Cell, DowngradingRow, TaskRow, WeighingLineRow } from 'ui/entries';
import { getStatusByValue } from 'utils';
import { Text } from 'ui/typography';
import Downgrading from 'components/Entries/Downgrading';
import { isNumber } from 'lodash';

const NO_INFO = 'NR';

const Wrapper = styled(Stack).attrs({
  size: 'full',
  direction: 'column',
})`
  border-top: 1px solid ${({ theme }) => theme.separator};
`;

const getExpectedValue = weighingLine => {
  if (weighingLine?.identifier) {
    return weighingLine?.identifier;
  } else if (
    isNumber(weighingLine?.containerCount) &&
    isNumber(weighingLine?.orderLineResource?.estimatedContainerCount)
  ) {
    return `${weighingLine?.containerCount}/${weighingLine?.orderLineResource?.estimatedContainerCount}`;
  } else if (isNumber(weighingLine?.containerCount)) {
    return weighingLine?.containerCount;
  }
  return NO_INFO;
};

const WeighingTaskRow = ({ task, weighingId }) => {
  const theme = useTheme();
  const title = `${task?.product?.service?.name} de ${
    task.product?.resource?.name
  } ${task?.quantity > 1 ? `x${task?.quantity}` : ''}`;
  const ref = `${weighingId}-${task?.id}`;
  const statusInfo = getStatusByValue(task?.status);

  return (
    <Wrapper>
      <TaskRow>
        <Cell gridArea="work-order">
          <Stack direction="column" size="full" gutterSize={0.25}>
            <strong>{title}</strong>
            <div style={{ color: theme.textLighten }}>{ref}</div>
            <Status
              rounded
              backgroundColor={`${statusInfo?.color}Tag`}
              color={`${statusInfo?.color}Label`}
            >
              {statusInfo?.label}
            </Status>
          </Stack>
        </Cell>
        <Cell gridArea="weighing-line" noPadding>
          <Stack size="full" direction="column">
            {task?.weighingLines?.map(weighingLine => {
              const weighingLineName =
                weighingLine?.containerResource?.name ??
                weighingLine?.orderLineResource?.containerResource?.name +
                  (weighingLine?.orderLineResource?.estimatedContainerCount > 1
                    ? ` x${weighingLine?.orderLineResource?.estimatedContainerCount}`
                    : '');

              return (
                <WeighingLineRow key={weighingLine?.id}>
                  <Cell gridArea="material">
                    <Text
                      color={
                        weighingLine?.extraContainerAlert ? 'error' : undefined
                      }
                    >
                      {weighingLineName}
                    </Text>
                  </Cell>
                  <Cell gridArea="expected">
                    <Text
                      textAlign="end"
                      color={
                        weighingLine?.containerCountAlert ||
                        weighingLine?.missingIdentifierAlert
                          ? 'error'
                          : undefined
                      }
                    >
                      {getExpectedValue(weighingLine)}
                    </Text>
                  </Cell>
                  <Cell gridArea="weight">
                    <Text
                      textAlign="end"
                      color={
                        weighingLine?.missingMeasurementAlert
                          ? 'error'
                          : undefined
                      }
                    >
                      {weighingLine?.missingIdentifierAlert ||
                      !weighingLine?.measurement
                        ? NO_INFO
                        : `${weighingLine?.measurement} 
                      ${
                        weighingLine?.measurement
                          ? task?.product?.resource?.unit
                          : ''
                      }`}
                    </Text>
                  </Cell>
                </WeighingLineRow>
              );
            })}

            {task?.weighingLines?.length !== 0 && (
              <>
                {task?.product?.resource?.unit !== 'un' && (
                  <WeighingLineRow>
                    <Cell gridArea="expected">
                      <Text textAlign="end" color="textLight">
                        Total tare :
                      </Text>
                    </Cell>
                    <Cell gridArea="weight">
                      <Text textAlign="end">
                        {task?.totalTare}{' '}
                        {task?.totalTare ? task?.product?.resource?.unit : ''}
                      </Text>
                    </Cell>
                  </WeighingLineRow>
                )}
                <WeighingLineRow>
                  <Cell gridArea="expected">
                    <Text textAlign="end" fontWeight="bold">
                      {task?.product?.resource?.unit === 'un'
                        ? 'Total :'
                        : 'Total net tare déduite :'}
                    </Text>
                  </Cell>
                  <Cell gridArea="weight">
                    <Text
                      textAlign="end"
                      fontWeight="bold"
                      color={task?.totalMeasurementAlert ? 'error' : undefined}
                    >
                      {task?.totalMeasurement}{' '}
                      {task?.totalMeasurement
                        ? task?.product?.resource?.unit
                        : ''}
                    </Text>
                  </Cell>
                </WeighingLineRow>
              </>
            )}
          </Stack>
        </Cell>

        <Cell gridArea="reporting">
          <Stack direction="column" gutterSize={1} size="full" alignX="end">
            {(!['unsto', 'pres'].includes(task?.product?.unit?.value) ||
              (task?.product?.unit?.value === 'unsto' &&
                task?.weighingLines?.length === 0)) &&
              task?.product?.billingMethod !== 'fixed' && (
                <Text>
                  {task?.billingMeasurement}{' '}
                  {task?.billingMeasurement ? task?.product?.unit?.label : ''}
                </Text>
              )}
            {task?.reportWeighings?.map(item => (
              <Text key={item?.id}>
                {item?.measurement} {item?.measurement ? item?.unit?.label : ''}
              </Text>
            ))}
          </Stack>
        </Cell>
      </TaskRow>
      {task?.downgrading && (
        <DowngradingRow>
          <Cell gridArea="downgrading">
            <Downgrading
              downgrading={task?.downgrading}
              productResourceUnit={task?.product?.resource?.unit}
            />
          </Cell>
        </DowngradingRow>
      )}
    </Wrapper>
  );
};

export default WeighingTaskRow;
